













































































































































































































































import GroupDripCampaignModal from '@/automation/drip-campaigns/components/GroupDripCampaignModal.vue';
import SendMessageModal from '@/communications/messages/components/SendMessageModal.vue';
import { EventTypes } from '@/constants/event-type-constants';
import { SortConstants } from '@/constants/sort-constants';
import { BaseStatuses } from '@/constants/status-constants';
import { getPagination } from '@/core/datatables-utils';
import { ApiRequestMixin } from '@/core/mixins/api-request-mixin';
import { addDays, differenceInDaysLocal } from '@/date-time/date-time-utils';
import {
    getAgeFromBirthDate,
    getCanBulkDelete,
    getChildNameFromOpportunity,
    getGuardianNamesFromOpportunity
} from '@/families/families-utils';
import {
    DownloadFamiliesParameters,
    Opportunity,
    OpportunitySortKeys,
    OpportunitySortParameter
} from '@/families/models/opportunity';
import { OpportunitiesRepository } from '@/families/repositories/opportunities-repository';
import { LocaleMixin } from '@/locales/locale-mixin';
import { DataTableOptions } from '@/models/datatables';
import { Center } from '@/organizations/locations/models/center';
import { ApiParameters } from '@/repositories/abstract-repository';
import { AppStateStore } from '@/store/app-state-store';
import { Org } from '@/models/organization/org';
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { DataTableHeader } from 'vuetify';
import { getModule } from 'vuex-module-decorators';
import pluralize from 'pluralize';
import { FamiliesFilterStore } from '@/filters/store/families-filter-store';
import { FamiliesFilter } from '@/filters/models/families-filter';
import { getSimpleFilterDto } from '@/filters/families-filter-utils';
import { FeatureConstants } from '@/features/feature-constants';
import { FeaturesStore } from '@/features/features-store';
import ApplyMarketingCampaign from '@/families/components/ApplyMarketingCampaign.vue';
import { StatusesStore } from '@/families/store/statuses-store';
import store from '@/store';
import { AuthStore } from '@/store/auth-store';
import { LayoutTabsStore } from '@/store/layout-tabs-store';
import hasha from 'hasha';
import { LoadingStore } from '@/store/loading-store';
import { StaffUtils } from '@/staff/staff-utils';
import { PermissionName } from '@/staff/models/user-permission-models';
import BaseClose from '@/components/base/BaseClose.vue';
import GroupDeleteRejectedFamilies from '@/families/components/GroupDeleteRejectedFamilies.vue';

const opportunitiesRepo = new OpportunitiesRepository();
const appState = getModule(AppStateStore);
const familiesFiltersStore = getModule(FamiliesFilterStore);
const featuresStore = getModule(FeaturesStore);
const statusStore = getModule(StatusesStore, store);
const authState = getModule(AuthStore, store);
const layoutTabsStore = getModule(LayoutTabsStore, store);
const loadingState = getModule(LoadingStore);
const staffUtils = new StaffUtils();

@Component({
    components: {
        GroupDeleteRejectedFamilies,
        BaseClose,
        ApplyMarketingCampaign,
        GroupDripCampaignModal,
        SendMessageModal
    }
})
export default class FamiliesTable extends Mixins(ApiRequestMixin, LocaleMixin) {
    // If we're filtering by status, set this
    @Prop() statusFilter: string | undefined;

    private loadingKey = 'familiesTable';
    private items: Array<Opportunity> = [];
    private currentItemCount = 1;
    private options: DataTableOptions = { itemsPerPage: 10, page: 1 };
    private currentRequestHash: string | null = null;
    private showDripCampaignModal = false;
    private showAddMarketingCampaignModal = false;
    private showDeleteRejected = false;
    private defaultSorting: OpportunitySortParameter = {
        sort_keys: [OpportunitySortKeys.DateAdded],
        sort_dir: [SortConstants.DESCENDING]
    };

    // messaging variables
    private messageExcluded: Array<number> = [];
    private messageIncluded: Array<number> = [];
    private messageSelected: Array<Opportunity> = [];
    private messageIncludeMode = false;
    private isSendMessage = false;
    private closeEvent = EventTypes.CLOSE;
    private sentEvent = EventTypes.MESSAGE_SENT;
    private campaignAddedEvent = EventTypes.ADDED;
    private deletedEvent = EventTypes.DELETED;
    private isDownloaded = false;
    private canSendGroup = true;
    private hasBulkDelete = false;

    public async created() {
        this.canSendGroup = await staffUtils.getUserPermission(PermissionName.FamilySendGroup);
        this.hasBulkDelete = await staffUtils.getUserPermission(PermissionName.FamilyBulkDelete);
    }

    get center(): Center | null {
        return appState.storedCurrentCenter;
    }

    get org(): Org | null {
        return appState.storedCurrentOrg;
    }

    get currentFilter(): FamiliesFilter | null {
        return familiesFiltersStore.currentFilter;
    }

    get messageMode(): boolean {
        return appState.messageMode;
    }

    set messageMode(flag: boolean) {
        appState.setFamilyMessageMode(flag);
    }

    get canDeleteRejected() {
        return this.hasBulkDelete && getCanBulkDelete(this.currentFilter);
    }

    get noMessagesSelected(): boolean {
        if (this.messageIncludeMode) {
            return this.messageIncluded.length === 0;
        }
        return this.messageExcluded.length === this.currentItemCount;
    }

    get currentFilterId() {
        return this.currentFilter ? this.currentFilter.id : 0;
    }

    get showFamilyScore() {
        if (!this.hasFamilyScore) {
            return false;
        }
        if (this.statusFilter && this.statusFilter === 'active') {
            return true;
        }
        if (this.statusFilter && this.statusFilter === 'custom' && this.currentFilter) {
            const filterStatuses = this.currentFilter.statuses.map((status) => {
                return status.id;
            });
            const activeIntersect = filterStatuses.filter(value => statusStore.activeStatusIds.includes(value));
            if (activeIntersect.length) {
                return true;
            }
        }
        return false;
    }

    get tableHeaders(): Array<DataTableHeader> {
        // Using the sort keys as column headers to make it easier to pass the sort params to the API
        const headers: Array<DataTableHeader> = [];
        if (this.showFamilyScore) {
            headers.push({
                text: 'Family Score',
                value: OpportunitySortKeys.FamilyScore,
                align: 'center',
                class: 'family-score-header-col'
            });
        }
        headers.push(
            {
                text: 'Guardian Name(s)',
                value: OpportunitySortKeys.GuardianLastName,
                class: 'guardian-name-header-col'
            },
            {
                text: 'Child Name',
                value: OpportunitySortKeys.ChildLastName,
                class: 'child-name-header-col'
            },
            {
                text: 'Child Birthdate',
                value: OpportunitySortKeys.ChildBirthDate,
                class: 'child-birthdate-header-col'
            }
        );
        if (!this.center) {
            headers.push({
                text: 'Location',
                value: OpportunitySortKeys.Location,
                class: 'location-header-col'
            });
        }
        headers.push(
            {
                text: 'Date Added',
                value: OpportunitySortKeys.DateAdded,
                class: 'added-header-col'
            },
            {
                text: 'Status',
                value: OpportunitySortKeys.Status,
                class: 'status-header-col'
            },
            {
                text: 'Status Date',
                value: OpportunitySortKeys.StatusDateTime,
                class: 'status-date-header-col'
            }
        );
        if (this.center) {
            headers.push({
                text: 'Exp. Start',
                value: OpportunitySortKeys.ChildExpectedStartDateTime,
                class: 'expected-start-date-header-col'
            });
        }
        headers.push({
            text: 'Last Contact',
            value: OpportunitySortKeys.LastContacted,
            class: 'last-contact-header-col'
        });
        return headers;
    }

    get hasDripCampaigns(): boolean {
        return featuresStore.hasDripCampaigns;
    }

    get hasFamilyScore(): boolean {
        return featuresStore.isFeatureEnabled(FeatureConstants.FAMILY_SCORE);
    }

    get hasMarketingCampaigns(): boolean {
        return featuresStore.isFeatureEnabled(FeatureConstants.MARKETING_CAMPAIGNS);
    }

    get isCrmPlus(): boolean {
        return featuresStore.isFeatureEnabled(FeatureConstants.CRM_PLUS_MODE);
    }

    get newTabStatus(): boolean {
        return appState.familyHubTargetMode;
    }

    get canAddCrmTabs(): boolean {
        return featuresStore.isFeatureEnabled(FeatureConstants.INCONTACT) && authState.isEnrollmentTeamMember;
    }

    @Watch('messageMode')
    async messageModeUpdated() {
        this.messageExcluded = [];
        this.messageIncluded = [];
        this.messageIncludeMode = false;
        this.updateSelected();
    }

    // Watch for changes to the Datatables options -- pagination, sorting, etc.
    @Watch('options')
    async onOptionsChange() {
        await this.getData();
    }

    @Watch('statusFilter', { immediate: true })
    async changeStatusFilters() {
        this.$set(this.options, 'page', 1);
        if (this.statusFilter === 'custom') {
            if (!familiesFiltersStore.isCustom) {
                // Got back to the active families tabs.
                await this.$router.push({
                    name: 'families-filtered',
                    params: {
                        statusFilter: 'active'
                    }
                });
            }
        }
        await this.getData();
    }

    @Watch('org', { deep: true, immediate: true })
    async changeOrg() {
        this.$set(this.options, 'page', 1);
        await this.getData();
    }

    private getSortingFromOptions(options: DataTableOptions): OpportunitySortParameter {
        let sorting = this.defaultSorting;
        if (options.sortBy && options.sortBy.length) {
            // Right now we don't support multiSort; only single column sort
            // It works in the API, but I don't want to implement it here yet
            sorting = {
                sort_keys: [options.sortBy[0] as OpportunitySortKeys],
                sort_dir: [
                    options.sortDesc && options.sortDesc[0]
                        ? SortConstants.DESCENDING
                        : SortConstants.ASCENDING
                ]
            };
        }
        return sorting;
    }

    async getData(forceReload = false) {
        if (!this.org) {
            // never load default org
            return;
        }
        await statusStore.init();
        const filters = this.statusFilter === 'custom' ? this.getFamilyFilters() : await this.getStatusFilters();
        const params = {
            pagination: getPagination(this.options),
            options: this.getSortingFromOptions(this.options),
            filters: filters
        };
        const hash = hasha(JSON.stringify(params), {
            algorithm: 'md5',
            encoding: 'hex'
        });
        if (!forceReload && this.currentRequestHash === hash) {
            // Duplicated request; skip it
            return;
        }

        this.setAbortController();

        loadingState.loadingIncrement(this.loadingKey);
        this.currentRequestHash = hash;

        const data = await opportunitiesRepo.getSorted(
            params.pagination,
            params.options,
            params.filters,
            this.abortController
        );
        this.clearAbortController();
        this.items = data.entities;
        this.currentItemCount = Number(data.count);
        this.updateSelected();
        loadingState.loadingDecrement(this.loadingKey);
    }

    private async startMsg() {
        this.isSendMessage = true;
    }

    private async startCmpgn() {
        this.showAddMarketingCampaignModal = true;
    }

    private async downloadFamilies() {
        this.isDownloaded = true;
        const downloadParams: DownloadFamiliesParameters = {};
        if (this.messageIncluded.length > 0) {
            downloadParams.family_ids = this.messageIncluded;
        } else if (this.currentFilterId > 0) {
            downloadParams.marketing_group_id = this.currentFilterId;
            if (this.messageExcluded.length > 0) {
                downloadParams.excluded_family_ids_from_group = this.messageExcluded;
            }
        }
        await opportunitiesRepo.downloadSelectedFamilies(downloadParams);
    }

    private itemsToggled(payload: { items: Array<Opportunity>; value: boolean }) {
        this.messageExcluded = [];
        this.messageIncluded = [];
        this.messageIncludeMode = !payload.value;
        this.updateSelected();
    }

    private itemSelected(payload: { item: Opportunity; value: boolean }) {
        if (payload.value) {
            // opp clicked on
            if (this.messageIncludeMode) {
                this.messageIncluded.push(payload.item.family_id);
            } else {
                const arrayInd = this.messageExcluded.indexOf(payload.item.family_id);
                if (arrayInd >= 0) {
                    this.messageExcluded.splice(arrayInd, 1);
                }
            }
        } else {
            // opp clicked off
            if (this.messageIncludeMode) {
                const arrayInd = this.messageIncluded.indexOf(payload.item.family_id);
                if (arrayInd >= 0) {
                    this.messageIncluded.splice(arrayInd, 1);
                }
            } else {
                this.messageExcluded.push(payload.item.family_id);
            }
        }
        this.updateSelected();
    }

    async postDeleteRejected() {
        this.messageMode = false;
        this.$set(this.options, 'page', 1);
        await this.getData(true);
    }

    private updateSelected() {
        if (!this.messageMode) {
            this.messageSelected = [];
            return;
        }
        this.messageSelected = this.items.filter((opp) => {
            if (this.messageIncludeMode) {
                return this.messageIncluded.includes(opp.family_id);
            }
            return !this.messageExcluded.includes(opp.family_id);
        });
    }

    @Watch('currentFilter')
    public updateCustomFilteredTable() {
        if (this.$route.params && this.$route.params.statusFilter === 'custom') {
            this.changeStatusFilters();
        }
    }

    /**
     * Some wrapper functions to get data
     */
    getDaysAgo(opportunity: Opportunity) {
        if (!opportunity.last_contact_datetime) {
            return 'Never';
        }
        const daysAgo = differenceInDaysLocal(opportunity.last_contact_datetime, new Date().toISOString());
        return `${daysAgo} ${pluralize('day', daysAgo)} ago`;
    }

    getGuardianNames(opportunity: Opportunity): string {
        return getGuardianNamesFromOpportunity(opportunity);
    }

    getChildName(opportunity: Opportunity): string {
        return getChildNameFromOpportunity(opportunity);
    }

    getChildOppCount(opportunity: Opportunity): number {
        return (opportunity.child?.additional_opportunity_count ?? 0) + 1;
    }

    getStatusDateTime(opportunity: Opportunity): string {
        if (opportunity.status_datetime === null) {
            return '';
        }

        return this.formatDate(opportunity.status_datetime);
    }

    getAddedDateTime(opportunity: Opportunity): string {
        if (opportunity.system_date === null) {
            return '';
        }

        return this.formatDate(opportunity.system_date);
    }

    getChildBirthDate(opportunity: Opportunity): string {
        if (opportunity.child === null || !opportunity.child.birth_date) {
            return '';
        }
        const childBirthDate = addDays(opportunity.child.birth_date, +1);
        return this.formatDate(childBirthDate);
    }

    getExpectedStartDateTime(opportunity: Opportunity): string {
        if (opportunity.child === null || !opportunity.child.expected_start_datetime) {
            return '';
        }
        const expectedStartDateTime = opportunity.child.expected_start_datetime;
        return this.formatDate(expectedStartDateTime);
    }

    getChildAge(opportunity: Opportunity): string {
        if (opportunity.child === null || !opportunity.child.birth_date) {
            return '';
        }
        const childBirthDate = addDays(opportunity.child.birth_date, +1);
        const birthdate = new Date(childBirthDate);
        const today = new Date();
        if (birthdate >= today) {
            return ' (due date)';
        }
        return ' (' + getAgeFromBirthDate(childBirthDate) + ')';
    }

    getStatus(opportunity: Opportunity): string {
        return opportunity.status.values.name;
    }

    /**
     * Get the status filters we need
     * @private
     */
    private async getStatusFilters(): Promise<ApiParameters> {
        const filters: ApiParameters = {};
        let statusIds: Array<number> = [];

        // Set the status filters based on which tab is active
        // I'm not a huge fan of doing it based off strings way, so open to suggestions
        switch (this.statusFilter) {
            case 'active':
                statusIds = statusStore.activeStatusIds;
                break;
            case 'enrolled':
                statusIds = [BaseStatuses.ENROLLED];
                break;
            case 'withdrawn':
                statusIds = [BaseStatuses.WITHDRAWN];
                break;
            case 'lost':
                statusIds = [BaseStatuses.LOST_OPP];
                break;
            default:
                break; // Don't know what to do here
        }

        if (statusIds.length) {
            filters.status_ids = statusIds;
        }

        if (this.org) {
            filters.org_ids = [this.org.id];
        }

        if (this.org && statusIds.length) {
            // handle standard status filters with simple backend marketing groups
            const filterDto = getSimpleFilterDto(statusIds, this.org ? this.org.id : 1);
            familiesFiltersStore.setIsCustom(false);
            await familiesFiltersStore.applyAnonymousFilter(filterDto);
            filters.marketing_group_id = familiesFiltersStore.currentFilter!.id;
        }

        return filters;
    }

    /**
     * Get filters to filter the families on.
     * @private
     */
    private getFamilyFilters(): ApiParameters {
        const filters: ApiParameters = {};

        const currentFilter = familiesFiltersStore.currentFilter;
        if (currentFilter) {
            filters.marketing_group_id = currentFilter.id;
        }
        if (this.org) {
            filters.org_ids = [this.org.id];
        }

        return filters;
    }

    private async goToFamilyHub(item: Opportunity) {
        if (this.newTabStatus) {
            if (this.canAddCrmTabs) {
                await layoutTabsStore.addTab({ routeName: 'family-hub', routeParams: { id: String(item.family_id) }, goTo: true, tabTitle: item.primary_guardian.last_name });
                return;
            }

            const routeData = this.$router.resolve({ name: 'family-hub', params: { id: item.family_id.toString() } });
            window.open(routeData.href, '_blank');
        } else {
            await this.$router.push({ name: 'family-hub', params: { id: item.family_id.toString() } });
        }
    }
}
