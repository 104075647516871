

























import { Component, Prop, PropSync, Vue, Watch } from 'vue-property-decorator';
import { AsYouType, parsePhoneNumber } from 'libphonenumber-js';
import type { CountryCode } from 'libphonenumber-js';
import BaseTextField from './BaseTextField.vue';

@Component({
    components: { 'base-text-field': BaseTextField }
})
export default class PhoneNumberInput extends Vue {
    @Prop({ required: true }) readonly countryCode!: CountryCode;
    @Prop() readonly label!: string;
    @Prop({ default: false }) readonly required!: boolean;
    @PropSync('e164', { type: String }) localE164!: string;
    @Prop({ default: false }) readonly hideDetails!: boolean | string;
    @Prop({ default: false }) readonly isInlineEditable!: boolean;
    @Prop({ default: false }) readonly validate!: boolean;

    private isValid = false;
    private formattedNumber = '';
    private initial = this.localE164;

    public mounted() {
        if (this.localE164) {
            this.formattedNumber = (parsePhoneNumber(this.localE164)).formatNational();
        }
    }

    @Watch('initial', { immediate: true })
    public translateToFormatted(number: string | null) {
        if (!number || number === '') {
            this.formattedNumber = '';
        } else {
            const asYouType = new AsYouType(this.countryCode);
            this.formattedNumber = asYouType.input(number);
        }
    }

    @Watch('formattedNumber', { immediate: true })
    public translateToE164(number: string) {
        const asYouType = new AsYouType(this.countryCode);
        let formatted = '';

        if (number) {
            formatted = asYouType.input(number);
            this.localE164 = '';
            this.isValid = false;

            if (asYouType.getNumber()) {
                if (asYouType.getNumber()!.isPossible()) {
                    this.localE164 = String(asYouType.getNumber()!.number);
                    this.isValid = true;
                }
            }
        }

        this.$emit('input', formatted);

        if ((formatted && formatted.length > 0)) {
            this.formattedNumber = formatted;
        } else if (!this.required && number === '') {
            this.formattedNumber = '';
            this.localE164 = '';
            this.isValid = true;
        }
    }

    get phoneNumberRules() {
        return [
            !this.formattedNumber || this.isValid || 'Please enter a valid phone number',
            !this.required || !!this.formattedNumber || 'Phone number required'
        ];
    }
}
