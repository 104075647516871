

































































// this is a composite component with everything needed for a normal date picker. use v-model to pass in
// date variable

import { EventTypes } from '@/constants/event-type-constants';
import { LocaleMixin } from '@/locales/locale-mixin';
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import BaseDatePicker from '@/components/base/BaseDatePicker.vue';
import BaseMenu from '@/components/base/BaseMenu.vue';
import { baseLocales } from '@/locales/locales';
import { formatDateForApi, baseFormatDate } from '@/date-time/date-time-utils';
import * as sugar from 'sugar';
import { getModule } from 'vuex-module-decorators';
import { FeaturesStore } from '@/features/features-store';
import { lineLeaderPurple } from '@/core/colors';

const featuresState = getModule(FeaturesStore);

@Component({
    components: { 'base-menu': BaseMenu, 'base-date-picker': BaseDatePicker }
})
export default class BaseDateInput extends Mixins(LocaleMixin) {
    $refs!: {
        input: HTMLElement;
    };

    private dateMenu = false;
    private formattedDate = '';
    private isValid = false;
    private date: Date = new Date();
    private mpPurple = lineLeaderPurple;

    @Prop(String) readonly label: string | undefined;
    @Prop({ default: false }) required!: boolean;
    @Prop({ default: undefined }) min!: string | undefined;
    @Prop({ default: undefined }) max!: string | undefined;
    @Prop({ default: false }) clearable!: boolean;
    @Prop({ default: false }) isDisabled!: boolean;
    @Prop({ default: false }) hideDetails!: boolean | string;
    @Prop({ default: false }) compactMode!: boolean;
    // Whether the content behind the date input is loading some additional data.
    @Prop({
        type: Boolean,
        default: false
    }) readonly isLoading!: boolean;

    @Prop({ default: (new Date()).toISOString().substring(0, 10) }) readonly value!: string;
    @Prop({ default: '' }) styling!: string;
    @Prop({ default: false }) readonly isInlineEditable!: boolean;

    get dateRules() {
        return [
            !this.required || !!this.localDate || 'Please choose a date',
            !this.formattedDate || this.isValid || 'Please enter a valid date'
        ];
    }

    get formatHint(): string {
        return baseLocales[this.$i18n.locale].dateFormat;
    }

    get isLineLeaderEnroll() {
        return featuresState.isLineLeaderEnroll;
    }

    get localDate() {
        return this.value;
    }

    set localDate(value) {
        this.$emit(EventTypes.INPUT, value);
    }

    // this helps to determine if the date was selected via the date picker menu or if it was typed in via text-field
    // so that we only trigger 'estimated date of birth' on typed-in inputs.
    get datesAreIdentical() {
        return sugar.Date.create(this.localDate, { locale: this.$i18n.locale }).getTime() === this.date.getTime();
    }

    created() {
        this.dateFormat();
    }

    @Watch('formattedDate', { immediate: true })
    checkForValidDateInput(dateString: string) {
        const validArray: Array<boolean> = [];
        if (dateString && dateString.length) {
            this.date = sugar.Date.create(this.formattedDate, { locale: this.$i18n.locale });
            validArray.push(sugar.Date.isValid(this.date));
            if (this.min) {
                validArray.push(sugar.Date.create(this.min, { locale: this.$i18n.locale }).getTime() <= this.date.getTime());
            }

            if (this.max) {
                validArray.push(sugar.Date.create(this.max, { locale: this.$i18n.locale }).getTime() >= this.date.getTime());
            }
            this.isValid = !validArray.includes(false);
            // tell the parent component to disable okay buttons if not valid.
            this.$emit(EventTypes.UPDATED, this.isValid);
        }
    }

    @Watch('localDate')
    dateFormat() {
        this.formattedDate = this.formatLocal();
    }

    clear() {
        this.formattedDate = '';
        this.localDate = '';
    }

    formatLocal(): string {
        if (!this.localDate) {
            return '';
        }

        // Simplest solution is to use the baseFormatDate to convert the date in ISO format from the date picker to
        // the current locale date format without worrying about mixing up timezones
        return baseFormatDate(this.localDate, this.formatHint);
    }

    processNaturalLanguageDate() {
        if (!this.formattedDate) {
            // Allow clearable dates
            this.localDate = '';
            return;
        }

        if (this.isValid) {
            this.formattedDate = baseFormatDate(this.date, this.formatHint);
            if (!this.datesAreIdentical) {
                this.$emit(EventTypes.DATE_PROCESSED);
            }
            this.localDate = formatDateForApi(this.date);
        } else if (!this.isValid && (this.min || this.max)) {
            // this is in case an invalid time is selected on the corp dash custom range picker, it'll revert to the initial time.
            // otherwise it will stay errored out and not let you select the initially selected time (because it hasn't technically changed).
            this.formattedDate = baseFormatDate(this.localDate, this.formatHint);
        }
    }
}
