











































import { Component, Prop } from 'vue-property-decorator';
import { getModule } from 'vuex-module-decorators';
import { AuthStore } from '@/store/auth-store';
import { NylasRepository } from '@/integrations/repositories/nylas-repository';
import Vue from 'vue';
import store from '@/store';

const authStore = getModule(AuthStore, store);
@Component
export default class ReauthenticatePopup extends Vue {
    @Prop({ default: false }) readonly value!: boolean;

    private nylasRepository = new NylasRepository();
    private loadingKey = 'reauthenticatePopup';
    private isReauthenticating = false;
    private successMessage: string | null = null;

    get modelValue(): boolean {
        return this.value;
    }

    set modelValue(showIt: boolean) {
        this.$emit('input', showIt);
    }

    get title(): string {
        const scope = authStore.getNylasScope;
        return scope === 1 ? 'Email Integration Update Notice' : 'Calendar Integration Update Notice';
    }

    get description(): string {
        const scope = authStore.getNylasScope;
        return scope === 1 ? 'Due to technical updates, your email integration needs to be reset. Please click the “Reauthenticate” button to receive an email that will contain step-by-step instructions to reset your email integration. Thank you.'
        : 'Due to technical updates, your calendar integration needs to be reset. Please click the “Reauthenticate” button to receive an email that will contain step-by-step instructions to reset your calendar integration. Thank you.';
    }

    private close() {
        this.modelValue = false;
        this.$emit('close');
        const query = { ...this.$route.query };
        if ('showPopup' in query) {
            delete query.showPopup;
        }
        this.$router.replace({
            path: this.$route.path,
            query
        });
        this.successMessage = null;
        authStore.setShowNylasReauthDialog(false);
        authStore.setNylasScope(null);
    }

    private async handleReauthenticate() {
        const scope = authStore.getNylasScope;
        const token = authStore.token;

        this.isReauthenticating = true;
        try {
            const response = await this.nylasRepository.sendReauthenticateEmail(scope, token);
            this.successMessage = 'Email sent successfully!';
            this.$emit('success', response);
        } catch (error) {
            this.$emit('error', error.response?.data || error.message);
        } finally {
            this.isReauthenticating = false;
        }
    }
}
