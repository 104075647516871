import { SixAgentPendingFamilySearchInfo } from '@/integrations/models/six-agent';
import { Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { StoreModuleTypes } from '@/constants/store-constants';
import store from '@/store/index';
import type { SixAgentPrefillInfo } from '@/integrations/models/six-agent';
import { SixAgentUtils } from '@/integrations/six-agent-utils';

export interface SixAgentState {
    visible: boolean;
    ready: boolean;
    needReZ: boolean;
    loggedIn: boolean;
    contactCount: number;
    eventLogged: boolean;
    activeContact: string | null;
    prefillInfoStack: Array<SixAgentPrefillInfo>;
    pendingFamilySearchInfo: SixAgentPendingFamilySearchInfo | null;
}

@Module({
    namespaced: true,
    name: StoreModuleTypes.SIX_AGENT,
    dynamic: true,
    store: store
})
export class SixAgentStore extends VuexModule implements SixAgentState {
    visible = false;
    ready = false;
    needReZ = false;
    loggedIn = false;
    contactCount = 0;
    eventLogged = false;
    activeContact: string | null = null;
    prefillInfoStack: Array<SixAgentPrefillInfo> = [];
    pendingFamilySearchInfo: SixAgentPendingFamilySearchInfo | null = null;

    @Mutation
    addPrefill(prefill: SixAgentPrefillInfo) {
        this.prefillInfoStack.push(prefill);
    }

    @Mutation
    deletePrefill(prefill: SixAgentPrefillInfo) {
        this.prefillInfoStack = this.prefillInfoStack.filter((existingPrefill) => {
            return !SixAgentUtils.matchPrefill(existingPrefill, prefill);
        });
    }

    @Mutation
    deletePrefillByContactId(contactId: string) {
        this.prefillInfoStack = this.prefillInfoStack.filter((existingPrefill) => {
            return contactId !== existingPrefill.contactId;
        });
    }

    @Mutation
    addPendingFamilySearchInfo(info: SixAgentPendingFamilySearchInfo) {
        this.pendingFamilySearchInfo = info;
    }

    @Mutation
    clearPendingFamilySearchInfo() {
        this.pendingFamilySearchInfo = null;
    }

    @Mutation
    public open() {
        this.visible = true;
        this.needReZ = true;
    }

    @Mutation
    public close() {
        this.visible = false;
    }

    @Mutation
    public setIsReady() {
        this.ready = true;
    }

    @Mutation
    public setReZDone() {
        this.needReZ = false;
    }

    @Mutation
    public setIsLoggedIn() {
        this.loggedIn = true;
    }

    @Mutation
    public setIsLoggedOut() {
        this.loggedIn = false;
        this.contactCount = 0;
        this.prefillInfoStack = [];
    }

    @Mutation
    public setActiveContact(contactId: string) {
        this.activeContact = contactId;
    }

    @Mutation
    public incrementContactCount() {
        this.contactCount++;
    }

    @Mutation
    public decrementContactCount() {
        this.contactCount--;
    }

    @Mutation
    public setEventLogged(flag: boolean) {
        this.eventLogged = flag;
    }

    get prefillInfo() {
        return this.prefillInfoStack.length ? this.prefillInfoStack[this.prefillInfoStack.length - 1] : null;
    }

    get pendingFamilySearchInfoData(): SixAgentPendingFamilySearchInfo | null {
        return this.pendingFamilySearchInfo;
    }
}
