export enum EventTypes {
    ACCEPTED = 'accepted',
    ADDED = 'added',
    CLICKED = 'clicked',
    CLOSE = 'close',
    CREATED = 'created',
    DELETED = 'deleted',
    DATE_PROCESSED = 'date-proccesed',
    COUNT = 'count',
    FAMILY_ACCEPTED = 'accepted',
    FAMILY_ADDED = 'family-added',
    FAMILY_REJECTED = 'rejected',
    FAMILY_MERGED = 'merged',
    FAMILY_VIEW = 'family-view',
    FILE_OPEN = 'file-open',
    FILE_REMOVE = 'file-remove',
    INBOX_READ = 'inbox-read',
    INBOX_SEARCH = 'inbox-search',
    INBOX_SEARCH_CLEARED = 'inbox-search-cleared',
    INPUT = 'input',
    LOADED = 'loaded',
    LOADING = 'loading',
    MARK_UNREAD = 'mark-unread',
    MESSAGE_SENT = 'message-sent',
    NEW_QUICK_TEXT = 'new-quick-text',
    NEW_FACEBOOK_MESSAGE = 'new-facebook-message',
    PENDING_FAMILY_COUNT = 'pending-count',
    START_REPLY='start-reply',
    REPLIED='replied',
    TOGGLE_ARCHIVED = 'toggle-archived',
    TOURS_COUNT = 'toursCount',
    UPDATED = 'updated',
    UPDATED_INBOX_COUNT = 'updated-inbox-count',
    CHILD_EXPORT_CHECKED = 'export-child-checkbox',
    CHILD_EXPORT_PENDING = 'export-child-pending',
    CONVERSION_SUCCESS_REPORT_STATUS_SELECTED = 'conversion-success-report-status-selected',
    CONVERSION_SUCCESS_REPORT_DATE_INPUT = 'conversion-success-report-date-input',
    CHILD_EXPORTED = 'export-child',
    DRIP_CAMPAIGNS_UPDATED = 'drip-campaigns-updated',
    FAMILY_UPDATED = 'family-updated',
    PENDING_EMAIL_CANCELLED = 'pending-email-cancelled',
    PENDING_EMAIL_UPDATED = 'pending-email-updated',
    PENDING_TEXT_CANCELLED = 'pending-text-cancelled',
    PENDING_TEXT_UPDATED = 'pending-text-updated',
    PHONE_NUMBER_BLOCKED = 'phone-number-blocked',
    SETTINGS_CHANGED = 'settings-changed',
    WORKFLOWS_UPDATED = 'workflows-updated',
    NEW_REPORT = 'new-report',
    SHARED_REPORT = 'shared-report',
    REPORT_DATE_FILTER_UPDATED = 'report-date-filter',
    REPORT_FILTER_ADDED = 'report-filter-added',
    REPORT_SCHEDULE_UPDATED = 'report-schedule-updated',
    REPORT_SCHEDULE_ADDED = 'report-schedule-added',
    REPORT_SCHEDULE_DELETED = 'report-schedule-deleted',
    REPORT_FIELDS_SELECTED = 'report-fields-selected',
    REPORT_VIEW_SELECTED = 'report-view-selected',
    REQUIRED_UPDATED = 'required-update',
    NAME_UPDATED = 'name-updated',
    STATUSES_UPDATED = 'statuses-update',
    STATUS_DELETED = 'status-delete',
    MARKETING_CAMPAIGN_CHANGED = 'marketing-campaign-changed',
    DISABLE_SAVE = 'disable-save',
    NEXT = 'next',
    PREV = 'prev',
    LANDING_PAGE_SOURCE_TOGGLED = 'landing-page-source-toggled',
    UPLOADED = 'uploaded',
    VIEW = 'view',
    CHILD_ADDED = 'child-added',
    CHILD_DELETED = 'child-deleted',
    CHILD_EDITED = 'child-edited',
    FAMILY_DELETED = 'family-deleted',
    MEETING_ADD = 'meeting-new',
    MEETING_EDIT = 'meeting-edit',
    TASK_EDIT = 'task-edit',
    TASK_ADD_ANOTHER = 'task-add-another',
    ADD_LOCATION_CLICKED = 'add-location-clicked',
    EDIT_LOCATION_CLICKED = 'edit-location-clicked',
    OTHER_LOCATION_CLICKED = 'other-location-clicked',
    LOCATION_MAPPING_SAVED = 'location-mapping-saved',
    ROLES_MAPPING_SAVED = 'roles-mapping-saved',
    MODAL_OPENED = 'modal-opened',
    EVENT_EDIT = 'event-edit'
}
