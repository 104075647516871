















    import { Vue, Component, Prop } from 'vue-property-decorator';
    import { PwStrength } from '@/staff/pw-strength';

@Component({})
    export default class PasswordStrengthLabel extends Vue {
        @Prop({ type: Number, required: true }) readonly strength!: number;

        get strengthLabel() {
            return PwStrength.getStrengthLabel(this.strength);
        }

        get showError() {
            return this.strength < PwStrength.MIN_STRENGTH;
        }
    }
