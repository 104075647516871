



















































import { LocaleMixin } from '@/locales/locale-mixin';
import { Component, Mixins, Prop, PropSync } from 'vue-property-decorator';
import { emailValidationRules } from '@/crm-types/field-utils';
import BaseDateInput from '@/components/base/BaseDateInput.vue';
import { BasicValidationMixin } from '@/validation/basic-validation-mixin';

@Component({
    components: { BaseDateInput }
})
export default class InlineEditable extends Mixins(LocaleMixin, BasicValidationMixin) {
    $refs!: {
        input: Vue & {
            $el?: HTMLElement;
        };
    }

    @PropSync('input', { type: String }) inputSynced!: string;
    @Prop() readonly label!: string;
    @Prop({ type: String }) readonly colSize!: string;
    @Prop({ type: Boolean, default: false }) readonly isEmailInput!: boolean;
    @Prop({ type: Boolean, default: false }) readonly isPhoneInput!: boolean;
    @Prop({ type: Boolean, default: false }) readonly isRequired!: boolean;
    @Prop({ type: Boolean, default: false }) readonly overflowEllipsis!: boolean;

    private editable = false;

    created() {
        document.addEventListener('mousedown', this.handleClickOutside);
        document.addEventListener('keydown', this.handleKeyDown);
    }

    beforeDestroy() {
        document.removeEventListener('mousedown', this.handleClickOutside);
        document.removeEventListener('keydown', this.handleKeyDown);
    }

    get textClass() {
        return this.overflowEllipsis ? 'overflow-ellipsis' : '';
    }

    private handleKeyDown(event: KeyboardEvent) {
        if (event.key === 'Enter' && this.editable) {
            this.handleClose();
        }
    }

    private handleClickOutside(event: MouseEvent) {
        if (this.editable) {
            const element = this.$el as HTMLElement;
            if (!element.contains(event.target as Node)) {
                this.handleClose();
            }
        }
    }

    private handleClose() {
        this.editable = false;
    }

    private toggleEdit() {
        if (!this.editable) {
            this.editable = true;
            this.$nextTick(() => {
                if (this.$refs.input) {
                    if (this.$refs.input.$el) {
                        const input = this.$refs.input.$el.querySelector('input');
                        if (input) input.focus();
                    }
                }
            });
        }
    }

    get nonRequiredEmail() {
        return emailValidationRules();
    }

    get generateSpanText(): string {
        if (this.inputSynced === '') {
            return this.label;
        } else if (this.isPhoneInput) {
            return this.formatPhone(this.inputSynced);
        } else {
            return this.inputSynced;
        }
    }
}
