import { AbstractRepository } from '@/repositories/abstract-repository';
import { StatusChangeInterface } from '@/families/models/status';

export class StatusChangeRepository extends AbstractRepository<any> {
    protected endpoint = ''; // unused

    public async changeStatus(statusChange: StatusChangeInterface, skipValidation = false): Promise<void> {
        const queryParams = skipValidation ? { skip_validation: true } : {};
        await this.client.post(`families/${statusChange.family_id}/change-status`, statusChange, { params: queryParams });
    }

    public async fixStatusTracking(familyId: number): Promise<void> {
        await this.client.post(`families/${familyId}/fix-status`, {});
    }
}
