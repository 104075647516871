
    import { EventTypes } from '@/constants/event-type-constants';
    import FamilySearch from '@/families/components/FamilySearch.vue';
    import { OrgsRepository } from '@/repositories/orgs-repository';
    import { StaffUtils } from '@/staff/staff-utils';
    import { UserSettingsStore } from '@/staff/store/user-settings-store';
    import { LayoutTab, LayoutTabsStore } from '@/store/layout-tabs-store';
    import { Component, Mixins, Watch } from 'vue-property-decorator';
    import { LocaleMixin } from '@/locales/locale-mixin';
    import { AuthStore } from '@/store/auth-store';
    import { getModule } from 'vuex-module-decorators';
    import store from '@/store';
    import { MenuItem } from '@/models/menu-item';
    import { CentersStore } from '@/organizations/locations/stores/centers-store';
    import { intercomHashKey, satismeterKey, serverCountry, sixAgentHost, timeoutMinutes, vueEnv } from '@/core/env-vars';
    import { AppStateStore } from '@/store/app-state-store';
    import * as crypto from 'crypto-js';
    import { EmailsStore } from '@/communications/messages/stores/emails-store';
    import { TextsStore } from '@/communications/messages/stores/texts-store';
    import { User } from '@/staff/models/user';
    import { FeaturesStore } from '@/features/features-store';
    import { FeatureConstants } from '@/features/feature-constants';
    import { formatDateWithTimezone } from '@/date-time/date-time-utils';
    import { ClientInfoStore } from '@/organizations/corporate/stores/client-info-store';
    import { ClientInfo } from '@/organizations/corporate/models/client-info';
    import { PermissionName } from '@/staff/models/user-permission-models';
    import { TasksStore } from '@/tasks/stores/tasks-store';
    import satismeter from 'satismeter-loader';
    import {
        iceHandledEvents,
        SixAgentCrmEntityData,
        sixAgentCss,
        SixAgentEvent,
        SixAgentEventType,
        sixAgentHeight,
        sixAgentScript,
        sixAgentWidth
    } from '@/integrations/models/six-agent';
    import { SixAgentStore } from '@/integrations/store/six-agent-store';
    import { IncontactRepository } from '@/integrations/repositories/incontact-repository';
    import { LoadingStore } from '@/store/loading-store';
    import { LOADING_GLOBAL_KEY } from '@/constants/loading-constants';
    import { FacebookMessagesStore } from '@/communications/messages/stores/facebook-messages-store';
    import { FacebookStore } from '@/integrations/store/facebook-store';
    import { LayoutItemsUtil } from '@/utils/layout-items-util';
    import { RecordingsStore } from '@/communications/recordings/stores/recordings-store';
    import { SixAgentUtils } from '@/integrations/six-agent-utils';
    import { NylasUtils } from '@/integrations/nylas-utils';
    import { StaffRepository } from '@/staff/repositories/staff-repository';
    import { CustomDashboardsStore } from '@/dashboards/store/custom-dashboards-store';

interface ElementInterface {
        el: HTMLElement | undefined;
        mouseStartX: number;
        mouseStartY: number;
        elStartX: number;
        elStartY: number;
        oldTransition: string;
    }

    const appState = getModule(AppStateStore);
    const authState = getModule(AuthStore, store);
    const centersState = getModule(CentersStore);
    const clientInfoStore = getModule(ClientInfoStore);
    const emailsStore = getModule(EmailsStore);
    const layoutItemUtil = new LayoutItemsUtil();
    const layoutTabsStore = getModule(LayoutTabsStore, store);
    const orgsRepository = new OrgsRepository();
    const textsStore = getModule(TextsStore);
    const fbMessagesStore = getModule(FacebookMessagesStore);
    const staffUtils = new StaffUtils();
    const userSettingsState = getModule(UserSettingsStore);
    const featuresStore = getModule(FeaturesStore);
    const tasksStore = getModule(TasksStore);
    const sixAgentStore = getModule(SixAgentStore);
    const incontactRepo = new IncontactRepository();
    const loadingState = getModule(LoadingStore);
    const facebookState = getModule(FacebookStore);
    const recordingsStore = getModule(RecordingsStore);
    const staffRepository = new StaffRepository();
    const customDashboardsStore = getModule(CustomDashboardsStore);

    @Component
    export default class LayoutMixin extends Mixins(LocaleMixin) {

        private automationLandingPagesPermissionGrant = false;
        private clientInfo: ClientInfo | null = null;
        private drawerOpen = false;
        private familyId: any = null;
        private inboxCounted = false;
        private loadingKey = 'mainNav';
        private mainMenuItems: Array<MenuItem> = [];
        private pastDueToursCountedWithinLast30Days = false;
        private searchClass = 'visible';
        private settingsNavPermissionGrant = false;
        private showAddFamily = false;
        private showCustomerSwitch = false;
        private showStaffModal = false;
        private viewFamilyEvent = EventTypes.FAMILY_VIEW;
        private addedEvent = EventTypes.ADDED;
        private canAccessBothDashboards = false;
        private canAccessDripCampaigns = false;
        private canViewWorkflowAutomations = false;
        private canAccessReports = false;
        private canViewUserSettings = false;
        private canAccessMarketingCampaign = false;
        private canAccessMarketPlace = false;
        private canViewTemplatesRemindersAttachments = false;
        private canAccessAutomation = false
        private hasCustomDashboards = false;
        private recordingsCount = 0;
        private sixAgentWidth = sixAgentWidth;
        private sixAgentHeight = sixAgentHeight;
        private sixTriggeredLogout = false;
        private showHelp = false;
        private nylasReauthEmail = '';
        private nylasReauthOpen = false;
        private d: ElementInterface = {
            el: undefined,
            mouseStartX: 0,
            mouseStartY: 0,
            elStartX: 0,
            elStartY: 0,
            oldTransition: ''
        };

        $refs!: {
            search: FamilySearch;
        };

        get layoutTabsKey() {
            return layoutTabsStore.layoutTabsKey;
        }

        get mini() {
            return appState.isMini;
        }

        set mini(flag: boolean) {
            if (!this.isLineLeaderEnroll) {
                appState.setIsMini(flag);
            }
        }

        /**
         * Whether new main content layout tabs can be added.
         */
        get canAddTabs(): boolean {
            return featuresStore.isFeatureEnabled(FeatureConstants.INCONTACT) && authState.isEnrollmentTeamMember;
        }

        get currentCenter() {
            return appState.storedCurrentCenter;
        }

        /**
         * The label for the change database caption
         */
        private get currentDatabase() {
            if (this.isSuperuser) {
                return `(Currently Managing ${authState.currentCustomerDb})`;
            }
            return '';
        }

        get currentOrg() {
            return appState.storedCurrentOrg;
        }

        private get menuItems(): Array<MenuItem> {
            const inboxChildren = [
                {
                    text: 'Texts',
                    route: 'inbox-text-messages'
                },
                {
                    text: 'Emails',
                    route: 'inbox-email-messages'
                }
            ];

            if (this.hasFacebook) {
                inboxChildren.push({
                    text: 'Facebook',
                    route: 'inbox-facebook-messages'
                });
            }
            inboxChildren.push({
                text: 'Call Recordings',
                route: 'inbox-call-recordings'
            });

            const automationChildren = [];
            if (this.canViewTemplatesRemindersAttachments) {
                automationChildren.push(
                    {
                        text: 'Message Templates',
                        route: 'message-templates',
                        classes: []
                    },
                    {
                        text: 'Email Attachments',
                        route: 'email-attachments',
                        classes: []
                    },
                    {
                        text: 'Reminders',
                        route: 'reminders',
                        classes: []
                    }
                );
            }

            if (!this.isCrmPlus || this.automationLandingPagesPermissionGrant || this.isSuperuser) {
                automationChildren.push({
                    text: 'Landing Pages',
                    route: 'landing-pages',
                    classes: []
                });
            }

            automationChildren.push(
                {
                    text: this.isLineLeaderEnroll ? 'PS Tours' : 'Parent-Scheduled Tours',
                    route: 'parent-scheduled-tours',
                    classes: []
                }
            );

            if (this.isCrmPlus && this.hasMarketingCampaigns && this.canAccessMarketingCampaign) {
                automationChildren.push({
                    text: this.isLineLeaderEnroll ? 'Campaigns' : 'Marketing Campaigns',
                    route: 'marketing-settings',
                    classes: []
                });
            }

            if (this.canViewWorkflowAutomations) {
                automationChildren.push({
                    text: this.isLineLeaderEnroll ? 'Automations' : 'Workflow Automations',
                    route: 'workflows-automation',
                    classes: []
                });
            }

            if (this.isCrmPlus && this.hasDripCampaigns && this.canAccessDripCampaigns) {
                automationChildren.push({
                    text: 'Drip Campaigns',
                    route: 'drip-campaigns',
                    classes: []
                });
            }

            if (this.canAccessMarketPlace) {
                automationChildren.push(
                    {
                        text: 'Marketplace',
                        route: 'marketplace',
                        classes: []
                    }
                );
            }

            // Apparently if you try to push and it doesn't match previous items, then it freaks out...
            const items: Array<MenuItem> = [];

            items.push(layoutItemUtil.getDashboardItems(
                this.canAccessBothDashboards,
                this.hasEnrollmentCenter,
                authState.isEnrollmentTeamMember,
                authState.isCenterStaff,
                this.$t('enrollment').toString(),
                this.isLineLeaderEnroll,
                this.hasCustomDashboards,
                customDashboardsStore.stored
            ));

            if (!featuresStore.isFeatureEnabled(FeatureConstants.LINE_LEADER_ENROLL)) {
                items.push(
                    {
                        text: 'Inbox',
                        route: 'inbox',
                        icon: 'email',
                        classes: [],
                        children: inboxChildren
                    }
                );
            }
            items.push(
                {
                    text: 'Families',
                    route: 'families',
                    icon: this.isLineLeaderEnroll ? 'family' : 'account-supervisor',
                    classes: []
                }
            );
            if (this.canAccessAutomation) {
                items.push({
                    text: 'Automation',
                    route: 'automation',
                    icon: this.isLineLeaderEnroll ? 'business-time' : 'briefcase',
                    classes: [],
                    children: automationChildren
                });
            }

            if (this.isCrmPlus) {
                const reportsChildren = [
                    {
                        text: 'Standard',
                        route: 'standard-reports',
                        classes: []
                    },
                    {
                        text: 'Custom ',
                        route: 'custom-reports',
                        classes: []
                    },
                    {
                        text: this.isLineLeaderEnroll ? 'Previous' : 'Previously Scheduled',
                        route: 'previously-scheduled',
                        classes: []
                    }
                ];
                if (this.isLegacyReportsEnabled) {
                    reportsChildren.push({
                        text: 'Legacy',
                        route: 'legacy-reports',
                        classes: []
                    });
                }

                reportsChildren.push({
                    text: this.isLineLeaderEnroll ? 'Group Comms' : 'Group Comm History',
                    route: 'group-comm-history-report',
                    classes: []
                });
                if (this.canAccessReports) {
                    items.push({
                        text: 'Reports',
                        route: 'reports',
                        icon: this.isLineLeaderEnroll ? 'chart-pie' : 'chart-line',
                        classes: [],
                        children: reportsChildren
                    });
                }
            }
            const settingsChildren = [
                {
                    text: 'Organization Info',
                    route: 'organization-settings',
                    classes: []
                }
            ];
            if (this.canViewUserSettings) {
                settingsChildren.push({
                    text: 'User Information',
                    route: 'user-information',
                    classes: []
                });
            }
            if (!this.isCrmPlus || authState.isCorporateUser || authState.isSuperuser) {
                settingsChildren.push({
                    text: 'System',
                    route: 'system-settings',
                    classes: []
                });
            }
            if (!this.isCrmPlus || this.settingsNavPermissionGrant || authState.isSuperuser) {
                items.push({
                    text: 'Settings',
                    route: 'settings',
                    icon: 'cog',
                    classes: [],
                    children: settingsChildren
                });
            }
            return items;
        }

        public get pastDueToursExistedWithinLast30Days() {
            return tasksStore.storedPastDueToursWithin30DaysCount > 0;
        }

        /**
         * The tabs for the main layout content.
         */
        get tabs(): Array<LayoutTab | Record<string, any>> {
            return layoutTabsStore.tabs;
        }

        get timezone() {
            return authState.userTimeZone;
        }

        private get today() {
            return formatDateWithTimezone(new Date(), this.timezone, 'd');
        }

        get currentTab() {
            return layoutTabsStore.currentTabIndex;
        }

        set currentTab(newIndex: number) {
            layoutTabsStore.setCurrentTabIndex(newIndex);
        }

        /**
         * Whether the db is in crm plus mode.
         */
        private get isCrmPlus() {
            return featuresStore.isFeatureEnabled(FeatureConstants.CRM_PLUS_MODE);
        }

        public get isLineLeaderEnroll() {
            return featuresStore.isFeatureEnabled(FeatureConstants.LINE_LEADER_ENROLL);
        }

        public get isLineLeaderManage() {
            return featuresStore.isFeatureEnabled(FeatureConstants.LINE_LEADER_MANAGE);
        }

        private get isLegacyReportsEnabled(): boolean {
            return featuresStore.isFeatureEnabled(FeatureConstants.LEGACY_REPORTS);
        }

        private get hasDripCampaigns(): boolean {
            return featuresStore.hasDripCampaigns;
        }

        private get hasFacebook(): boolean {
            return featuresStore.isFeatureEnabled(FeatureConstants.FACEBOOK_INTEGRATION) && facebookState.entities.length > 0;
        }

        private get hasEnrollmentCenter(): boolean {
            return featuresStore.isFeatureEnabled(FeatureConstants.ENROLLMENT_CENTER);
        }

        get hasMarketingCampaigns(): boolean {
            return featuresStore.isFeatureEnabled(FeatureConstants.MARKETING_CAMPAIGNS);
        }

        private get hasTodaysTours() {
            return featuresStore.isFeatureEnabled(FeatureConstants.TODAYS_TOURS);
        }

        private get hasTwoWayEmail(): boolean {
            return featuresStore.isFeatureEnabled(FeatureConstants.TWO_WAY_EMAILS);
        }

        private get hasTwoWayText(): boolean {
            return featuresStore.isFeatureEnabled(FeatureConstants.TWO_WAY_TEXTS);
        }

        get refreshMenuFlag() {
            return appState.refreshMenu;
        }

        private get sixAgentReady(): boolean {
            return sixAgentStore.ready;
        }

        private get sixAgentLoggedIn(): boolean {
            return sixAgentStore.loggedIn;
        }

        private get sixAgentVisible(): boolean {
            return sixAgentStore.visible;
        }

        private get sixAgentNeedReZ(): boolean {
            return sixAgentStore.needReZ;
        }

        private get showSixAgent(): boolean {
            if (!this.userObject) {
                return false;
            }
            return featuresStore.isFeatureEnabled(FeatureConstants.INCONTACT) && this.userObject.is_on_enrollment_team;
        }

        public get inboxCount() {
            return textsStore.inboxCount + emailsStore.inboxCount + fbMessagesStore.inboxCount + recordingsStore.inboxCount;
        }

        public get inboxCountText() {
            return this.inboxCounted ? this.inboxCount + '' : '';
        }

        public get emailCountText() {
            return this.inboxCounted ? emailsStore.inboxCount + '' : '';
        }

        public get textCountText() {
            return this.inboxCounted ? textsStore.inboxCount + '' : '';
        }

        public get facebookCountText() {
            return this.inboxCounted ? fbMessagesStore.inboxCount + '' : '';
        }

        public get callRecordingCountText() {
            return this.inboxCounted ? recordingsStore.inboxCount + '' : '';
        }

        /**
         * Whether the user is a superuser.
         */
        private get isSuperuser() {
            return authState.isSuperuser;
        }

        private get username(): string {
            return authState.username;
        }

        get userObject(): User | undefined {
            return authState.userInfoObject;
        }

        /**
         * The UserSettings for the current user.
         */
        get userSettings() {
            return userSettingsState.storedUserSettings;
        }

        @Watch('currentOrg', { deep: true })
        async orgUpdated() {
            this.$intercom.update({
                Location: this.currentOrg?.name ?? 'Unknown Organization'
            });
            // If needed, initialize the facebook count.
            await featuresStore.init();
            await this.initFacebook();
            await this.initInbox();
            await this.initPastDueToursCountWithIn30Days();
        }

        /**
         * Set the current tab id when changing which tab the user is viewing.
         */
        @Watch('currentTab')
        async currentTabChanged() {
            if (this.tabs[this.currentTab]) {
                layoutTabsStore.setCurrentTabId(this.tabs[this.currentTab].id);
            }
        }

        @Watch('refreshMenuFlag')
        menuNeedsRefresh() {
            this.refreshMenu();
        }

        @Watch('sixAgentNeedReZ')
        sixAgentReZChanged() {
            if (this.sixAgentNeedReZ) {
                const zArray = Array.from(document.querySelectorAll('body *'))
                    .map(a => parseFloat(window.getComputedStyle(a).zIndex))
                    .filter(a => !isNaN(a));
                const maxZ = Math.max(...zArray);
                const sixAgentWrapper = document.querySelector('.six-agent-modal')!.parentElement;
                sixAgentWrapper!.style.zIndex = String(maxZ + 1);
                sixAgentStore.setReZDone();
            }
        }

        public toggleSixAgent() {
            if (this.sixAgentVisible) {
                sixAgentStore.close();
                return;
            }
            sixAgentStore.open();
        }

        private closeSixAgent() {
            sixAgentStore.close();
        }

        private deactivateSub() {
            this.mainMenuItems.forEach((menuItem) => {
                if (menuItem.classes) {
                    const index = menuItem.classes.indexOf('sub-active');
                    if (index > -1) {
                        menuItem.classes.splice(index, 1);
                    }
                }
            });
        }

        private help() {
            this.showHelp = true;
        }

        // Watch the family id returned from the search and go to the page if valid
        @Watch('familyId')
        private goToFamily(): void {
            if (this.familyId !== null) {
                this.handleNav('family-hub', { id: this.familyId.toString() });
            }
        }

        @Watch('isAppIdle')
        public onIdle(flag: boolean) {
            if (flag) {
                this.logout();
            }
        }

        public async mounted() {
            window.addEventListener('beforeunload', () => {
                this.beforeClose();
            });

            if (authState.closedTime) {
                // we're entering logged in layout with a stored close time, so let's make
                // sure they didn't close the window too long ago
                const now = (new Date()).getTime();
                if ((now - authState.closedTime) > timeoutMinutes * 60 * 1000) {
                    await this.logout();
                }
            }

            // Keep track of how many windows we have open.
            authState.incrementInstances();
            authState.clearClose();

            const featuresInit = featuresStore.init();
            this.automationLandingPagesPermissionGrant = await staffUtils.getUserPermission(PermissionName.AutomationLandingPages);
            this.canAccessBothDashboards = await staffUtils.getUserPermission(PermissionName.DashboardBothViews);
            this.settingsNavPermissionGrant = await staffUtils.getUserPermission(PermissionName.SettingsNav);
            this.canAccessDripCampaigns = await staffUtils.getUserPermission(PermissionName.AutomationDripCampaign);
            this.canViewWorkflowAutomations = await staffUtils.getUserPermission(PermissionName.AutomationViewWorkflows);
            this.canAccessReports = await staffUtils.getUserPermission(PermissionName.ReportsAccess);
            this.canAccessMarketingCampaign = await staffUtils.getUserPermission(PermissionName.AutomationViewMarketing);
            this.canAccessMarketPlace = await staffUtils.getUserPermission(PermissionName.AutomationMarketplace);
            this.canViewUserSettings = await staffUtils.getUserPermission(PermissionName.SettingsUsers);
            this.canViewTemplatesRemindersAttachments = await staffUtils.getUserPermission(PermissionName.AutomationViewMessageTemplates);
            this.canAccessAutomation = await staffUtils.getUserPermission(PermissionName.AutomationAccess);
            this.hasCustomDashboards =
                this.isCrmPlus &&
                await staffUtils.getUserPermission(PermissionName.CustomDashboards) &&
                featuresStore.isFeatureEnabled(FeatureConstants.CUSTOM_DASHBOARDS);
            if (this.hasCustomDashboards) {
                await customDashboardsStore.init();
            }
            await clientInfoStore.init();
            await featuresInit;
            const facebookInit = this.initFacebook();
            this.clientInfo = clientInfoStore.storedClientInfo;
            const userSettingsInit = userSettingsState.init(authState.id as number);
            const pastDueToursWithIn30DaysInit = this.initPastDueToursCountWithIn30Days();
            const inboxInit = this.initInbox();
            const externalInit = this.initExternal();
            await userSettingsInit;
            await facebookInit;

            this.refreshMenu();

            let sixAgentInit;
            if (this.showSixAgent) {
                sixAgentInit = this.initSixAgent();
            }

            await pastDueToursWithIn30DaysInit;
            await inboxInit;
            await externalInit;

            if (sixAgentInit) {
                await sixAgentInit;
            }
            this.makeModalMovable();

            if (window.matchMedia('(max-width: 1903.98px)').matches) {
                this.mini = true;
            }

            // Create the initial tab, if needed.
            if (layoutTabsStore.tabs.length === 0) {
                const route = this.$route;
                const tabTitle = route?.meta?.tabTitle ?? undefined;

                await layoutTabsStore.addTab({
                    routeName: route.name ?? undefined,
                    routeParams: route.params,
                    goTo: true,
                    tabTitle: tabTitle
                });
            }
            if (authState.userInfoObject && NylasUtils.doesUserNeedReauth(authState.userInfoObject)) {
                this.nylasReauthEmail = authState.userInfoObject.nylas_email ?? '';
                this.nylasReauthOpen = true;
            }
        }

        private refreshMenu() {
            // Traverse the menu items, and figure out which parent menu needs to be highlighted, or not.
            // If you directly load a route, this method doesn't get called for some reason,
            // Since this wasn't really in the AC of CRM-9710, I'm not going any further to fix that issue.
            let i = 0;
            this.mainMenuItems = this.menuItems;
            this.mainMenuItems.forEach((parentItem) => {
                if (this.isLineLeaderEnroll) {
                    parentItem.expanded = false;
                }
                if (parentItem.children) {
                    parentItem.children.forEach((childItem) => {
                        if (this.$router.currentRoute.name === childItem.route) {
                            if (this.mainMenuItems[i] && this.mainMenuItems[i].classes) {
                                const classes = this.mainMenuItems[i]!.classes;

                                if (classes && !classes.includes('sub-active')) {
                                    classes.push('sub-active');
                                }
                            }
                        }
                    });
                }

                i++;
            });
        }

        public beforeClose() {
            if (authState.isLoggedIn) {
                authState.decrementInstances();
                authState.storeClose();
            }
        }

        // Stuff for the navigation.
        public activateSub(parentId: number) {
            // Remove classes from parents first.
            this.deactivateSub();

            // Activate.
            if (this.mainMenuItems && this.mainMenuItems[parentId] && this.mainMenuItems[parentId].classes) {
                const classes = this.mainMenuItems[parentId]!.classes;

                if (classes && !classes.includes('sub-active')) {
                    classes.push('sub-active');
                }
            }
        }

        /**
         * Add a new tab to the main content layout.
         */
        async addTab(goTo = false, routeName = 'home', routeParams?: Record<string, any>, tabTitle?: string) {
            await layoutTabsStore.addTab({
                routeName: routeName,
                routeParams: routeParams,
                goTo,
                tabTitle
            });
        }

        /**
         * Close a tab from the main content layout.
         */
        private async closeTab(index: number) {
            // craziness to get around weird bug
            const needExtra = (index === this.currentTab - 1 && index > 0);
            if (needExtra) {
                this.currentTab -= 2;
            } else if (index <= this.currentTab) {
                this.currentTab--;
            }

            layoutTabsStore.removeTab(this.tabs[index] as LayoutTab);

            if (needExtra) {
                await this.$nextTick();
                this.currentTab++;
            }
        }

        public collapseOtherNavs(tab: MenuItem | null = null) {
            this.mainMenuItems.forEach(item => {
                if ((tab && tab.route !== item.route) || (!tab)) {
                    item.expanded = false;
                }
            });
        }

        public makeItemActive(tab: MenuItem) {
            if (tab.classes && !tab.classes.includes('active-item')) {
                tab.classes.push('active-item');
            }
        }

        public makeEveryOtherMenuItemInactive() {
            this.mainMenuItems.forEach(menuItem => {
                if (menuItem.classes && menuItem.classes.length) {
                    const index = menuItem.classes.indexOf('active-item');
                    if (index > -1) {
                        menuItem.classes.splice(index, 1);
                    }
                }
                if (menuItem.children) {
                    menuItem.children.forEach(childMenuItem => {
                        if (childMenuItem.classes && childMenuItem.classes.length) {
                            const index = childMenuItem.classes.indexOf('active-item');
                            if (index > -1) {
                                childMenuItem.classes.splice(index, 1);
                            }
                        }
                    });
                }
            });
        }

        /**
         * Handle navigation indicated by the nav bar.
         *
         * @param name
         * @param params
         */
        handleNav(name: string, params: any | undefined) {
            const route = this.$router.resolve({ name: name }).route;
            const tab = this.tabs[this.currentTab];
            const tabTitle = params?.tabTitle ?? (route?.meta?.tabTitle ?? undefined);

            tab.props = {
                routeName: name,
                routeParams: params ?? undefined,
                tabTitle: tabTitle

            };

            this.tabs.splice(this.currentTab, 1, tab);

            if (this.currentTab === 0) {
                this.$router.push({
                    name: name,
                    params: params ?? undefined
                });
            }
        }

        /**
         * handler for six events
         * @param evt
         */
        public async handleEvent(evt: SixAgentEvent) {
            switch (evt.type) {
                case SixAgentEventType.AGENT_INITIALIZED:
                    sixAgentStore.setIsReady();
                    break;
                case SixAgentEventType.SHOW_OPENFRAME:
                    sixAgentStore.open();
                    break;
                case SixAgentEventType.AGENT_LOG_ON:
                    sixAgentStore.setIsLoggedIn();
                    sixAgentStore.incrementContactCount();
                    break;
                case SixAgentEventType.AGENT_LOG_OFF:
                    sixAgentStore.decrementContactCount();
                    sixAgentStore.setIsLoggedOut();
                    if (this.sixTriggeredLogout) {
                        layoutTabsStore.clear();
                        authState.storeLoggedOut();
                    }
                    break;
                case SixAgentEventType.INCONTACT_EVENT:
                    for (const ice of evt.data.events) {
                        if ('type' in ice && ice.type === 'CustomEvent' && ice.eventName === 'CRM_ENTITY') {
                            const entityData: SixAgentCrmEntityData = JSON.parse(ice.data);
                            if (!entityData.contactID) {
                                continue;
                            }
                            sixAgentStore.addPrefill({
                                contactId: entityData.contactID,
                                phoneNumber: SixAgentUtils.normalizePhone(entityData.customerPhone),
                                email: null
                            });
                            await layoutTabsStore.switchToOrAddTabByContactId({
                                routeName: 'six-agent-contact-search',
                                routeParams: {
                                    contactId: entityData.contactID,
                                    familyId: entityData.familyId,
                                    ani: entityData.customerPhone,
                                    schoolNum: entityData.schoolNum
                                },
                                goTo: entityData.contactID === sixAgentStore.activeContact,
                                contactId: entityData.contactID,
                                tabTitle: 'Contact Search: ' + (entityData.customerPhone ?? entityData.familyId ?? '')
                            });
                        }
                        if ('Status' in ice && ice.Status === 'Active') {
                            // we might have a race condition on the Active event vs
                            // CustomEvent, so mark the active contact here just in case
                            sixAgentStore.setActiveContact(ice.ContactId ?? ice.ContactID ?? '');
                            if (iceHandledEvents.includes(ice.Type)) {
                                await layoutTabsStore.switchToOrAddTabByContactId({
                                    routeName: 'six-agent-contact-search',
                                    routeParams: {
                                        contactId: ice.MasterID ?? ice.MasterId,
                                        workItemPayload: ice.WorkItemPayload ?? '',
                                        email: ice.FromAddress ?? ''
                                    },
                                    goTo: true,
                                    contactId: ice.ContactID ?? ice.ContactId ?? '',
                                    tabTitle: 'Contact Search: ' + (ice.ANI ?? ice.FromAddress ?? '')
                                });
                            } else {
                                // switch to already open tab
                                await layoutTabsStore.switchToTabByContactId(ice.ContactID ?? ice.ContactId ?? '');
                            }
                        }
                    }
                    break;
                case SixAgentEventType.NEW_CONTACT:
                    if (evt.data.IsInbound === 'True' && iceHandledEvents.includes(evt.data.Type)) {
                        sixAgentStore.addPrefill({
                            contactId: evt.data.ContactID ?? evt.data.ContactId ?? '',
                            phoneNumber: SixAgentUtils.normalizePhone(evt.data.ANI ?? null),
                            email: evt.data.FromAddress ?? null
                        });
                    }
                    break;
                case SixAgentEventType.END_CONTACT:
                    sixAgentStore.deletePrefillByContactId(evt.data.ContactID ?? evt.data.ContactId ?? '');
                    break;
                case SixAgentEventType.PHONE_LOG:
                    await incontactRepo.recordPhoneLog(evt);
                    sixAgentStore.setEventLogged(true);
                    await this.$nextTick();
                    sixAgentStore.setEventLogged(false);
                    break;
            }
        }

        public async initSixAgent() {
            const head = document.getElementsByTagName('head')[0];
            const script = document.createElement('script');
            const cssLink = document.createElement('link');
            cssLink.rel = 'stylesheet';
            cssLink.href = sixAgentHost + sixAgentCss;
            head.appendChild(cssLink);
            head.appendChild(script);
            script.addEventListener('load', async () => {
                try {
                    window.sixAgentInterface.init(this, {
                        // omni account in dev might need 'https://snow-cxone-cti-dev.skyboxcommunications.com',
                        sixHost: sixAgentHost,
                        isStandaloneMode: 'N',
                        frameId: 'sixAgentTarget',
                        width: this.sixAgentWidth,
                        height: this.sixAgentHeight,
                        exposeIncontactEvents: 'Y',
                        isTaskRefreshEnabled: 'N'
                    });
                    sixAgentStore.setIsReady();
                } catch (e) {
                }
            });
            script.src = sixAgentHost + sixAgentScript;
            // initial launch position on the left
            const modal = (document.querySelector('.six-agent-modal')! as HTMLElement);
            modal.style.left = '50px';
            modal.style.position = 'fixed';
        }

        public async initPastDueToursCountWithIn30Days() {
            this.pastDueToursCountedWithinLast30Days = false;
            const countInit = await tasksStore.initPastDueToursWithin30DaysCount(this.currentOrg?.id ?? 0);
            await countInit;
            this.pastDueToursCountedWithinLast30Days = true;
        }

        public async initFacebook() {
            if (featuresStore.isFeatureEnabled(FeatureConstants.FACEBOOK_INTEGRATION)) {
                await facebookState.init();
            }
        }

        public async initInbox() {
            if (!this.currentOrg) {
                return;
            }

            this.inboxCounted = false;
            const inboxPromises = [];

            if (this.hasTwoWayText) {
                inboxPromises.push(textsStore.initTextsInboxCount({ org_id: this.currentOrg.id }));
            }

            if (this.hasTwoWayEmail) {
                inboxPromises.push(emailsStore.initEmailsInboxCountForOrg({ org_id: this.currentOrg.id }));
            }
            if (this.hasFacebook) {
                inboxPromises.push(fbMessagesStore.initFacebookMessagesInboxCount({ org_id: this.currentOrg.id }));
            }
            inboxPromises.push(recordingsStore.initRecordingsInboxCount(this.currentOrg ? this.currentOrg.id : 1));

            await Promise.all(inboxPromises);

            this.inboxCounted = true;
        }

        public async initExternal() {
            const root = await orgsRepository.getOne(1);
            const userInfo = authState.userInfoObject;
            await centersState.initCount();
            if (root && userInfo) {
                this.$intercom.boot({
                    name: userInfo.first_name + ' ' + userInfo.last_name,
                    user: userInfo.username,
                    user_id: String(userInfo.id),
                    company: {
                        id: String(userInfo.client_id),
                        name: root.name,
                        client_size: String(centersState.count),
                        ent_client: '0',
                        server: serverCountry
                    },
                    email: userInfo.email_address,
                    Org: root.name,
                    Location: appState.storedCurrentOrg?.name ?? 'Unknown Organization',
                    Position: userInfo.position_title?.values.value ?? 'Unknown',
                    Product: featuresStore.isFeatureEnabled(FeatureConstants.CRM_PLUS_MODE) ? 'CRM+' : 'CRM',
                    dbid: String(userInfo.client_id),
                    server: serverCountry,
                    user_hash: String(crypto.HmacSHA256(String(userInfo.id), intercomHashKey)),
                    client_size: String(centersState.count),
                    ent_client: '0',
                    alignment: 'right',
                    custom_launcher_selector: '#intercom-activator',
                    hide_default_launcher: true
                });
                this.$intercom.hide();
                if (!staffUtils.isInternal(userInfo)) {
                    satismeter({
                        writeKey: satismeterKey,
                        userId: String(userInfo.id),
                        forceSurvey: vueEnv !== 'production',
                        traits: {
                            name: userInfo.first_name + ' ' + userInfo.last_name,
                            email: userInfo.email_address,
                            createdAt: userInfo.hire_time ?? 'Unknown',
                            company: {
                                id: String(userInfo.client_id),
                                name: root.name,
                                client_size: String(centersState.count),
                                ent_client: '0',
                                server: serverCountry
                            },
                            Org: root.name,
                            Location: appState.storedCurrentOrg?.name ?? 'Unknown Organization',
                            Position: userInfo.position_title ? userInfo.position_title.values.value : 'Unknown',
                            Product: this.isCrmPlus ? 'CRM+' : 'CRM',
                            dbid: String(userInfo.client_id),
                            server: serverCountry,
                            user_hash: String(crypto.HmacSHA256(String(userInfo.id), intercomHashKey)),
                            client_size: String(centersState.count),
                            ent_client: '0'
                        }
                    });
                }
            }
        }

        private async logout() {
            if (sixAgentStore.loggedIn) {
                this.sixTriggeredLogout = true;
                loadingState.loadingIncrement(LOADING_GLOBAL_KEY);
                window.sixAgentInterface.logout('Y');
                // make sure we actually logout just in case something went off sync
                window.setTimeout(() => {
                    this.$router.push({ name: 'logout' });
                }, 2000);
                return;
            }
            await this.$router.push({ name: 'logout' });
        }

        private makeModalMovable() {
            document.addEventListener('mousedown', e => {
                const closestDialog = (e.target as Element).closest('.v-dialog.v-dialog--active') as HTMLElement;
                if (e.button === 0 && ((e.target as Element).classList.contains('v-card__title') || (e.target as Element).classList.contains('popup-header'))) { // element which can be used to move element
                    this.d.el = closestDialog; // element which should be moved
                    this.d.mouseStartX = e.clientX;
                    this.d.mouseStartY = e.clientY;
                    this.d.elStartX = this.d.el.getBoundingClientRect().left;
                    this.d.elStartY = this.d.el.getBoundingClientRect().top;
                    this.d.el.style.position = 'fixed';
                    this.d.el.style.margin = '0px';
                    this.d.oldTransition = this.d.el.style.transition;
                    this.d.el.style.transition = 'none';
                    this.d.el.style.left = Math.min(
                        Math.max(this.d.elStartX + e.clientX - this.d.mouseStartX, 0),
                        window.innerWidth - this.d.el.getBoundingClientRect().width
                    ) + 'px';
                    const dTitle = this.d.el.querySelector('.v-card__title');
                    const boundHeight = dTitle ? dTitle.getBoundingClientRect().height : 0;
                    this.d.el.style.top = Math.min(
                        Math.max(this.d.elStartY + e.clientY - this.d.mouseStartY, 0),
                        window.innerHeight - boundHeight
                    ) + 'px';
                }
            });
            document.addEventListener('mousemove', e => {
                if (!this.d || !this.d.el) return;
                this.d.el.style.left = Math.min(
                    Math.max(this.d.elStartX + e.clientX - this.d.mouseStartX, 0),
                    window.innerWidth - this.d.el.getBoundingClientRect().width
                ) + 'px';
                const dTitle = this.d.el.querySelector('.v-card__title');
                const boundHeight = dTitle ? dTitle.getBoundingClientRect().height : 0;
                this.d.el.style.top = Math.min(
                    Math.max(this.d.elStartY + e.clientY - this.d.mouseStartY, 0),
                    window.innerHeight - boundHeight
                ) + 'px';
            });
            document.addEventListener('keydown', (evt) => {
                if (evt.key === 'Esc' || evt.key === 'Escape') {
                    if (this.d && this.d.el) {
                        this.d.el.style.transition = this.d.oldTransition;
                        this.d.el = undefined;
                    }
                }
            });
            document.addEventListener('click', () => {
                if (this.d && this.d.el) {
                    this.d.el.style.transition = this.d.oldTransition;
                    this.d.el = undefined;
                }
            });
            document.addEventListener('mouseup', () => {
                if (!this.d || !this.d.el) return;
                this.d.el.style.transition = this.d.oldTransition;
                this.d.el = undefined;
            });
            setInterval(() => { // prevent out of bounds
                const dialog = document.querySelector('.v-dialog.v-dialog--active') as HTMLElement | null;
                if (dialog === null) return;
                dialog.style.left = Math.min(parseInt(dialog.style.left), window.innerWidth - dialog.getBoundingClientRect().width) + 'px';
                const dTitle = dialog.querySelector('.v-card__title');
                const boundHeight = dTitle ? dTitle.getBoundingClientRect().height : 0;
                dialog.style.top = Math.min(parseInt(dialog.style.top), window.innerHeight - boundHeight) + 'px';
            }, 100);
        }

        async nylasAdded() {
            if (authState.id) {
                const userInfo = await staffRepository.getOne(authState.id);
                authState.storeUserInfo(userInfo);
            }
        }

        private showManageStaffModal() {
            if (!appState.manageStaffModalOpen) {
                appState.updateManageStaffModalStatus(true);
                this.showStaffModal = true;
            }
        }

        private submitFeedback() {
            window.open('https://app.smartsheet.com/b/form/38c5247f7f914950ae47db0be5f52d06', '_blank');
        }
    };
