





































import { EventTypes } from '@/constants/event-type-constants';
import { CrmTypeList, CrmTypeOption } from '@/crm-types/models/crm-type';
import { CrmTypesStore } from '@/crm-types/store/crm-types-store';
import { LoadingStore } from '@/store/loading-store';
import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import { getModule } from 'vuex-module-decorators';

const crmTypesStore = getModule(CrmTypesStore);
const loadingStore = getModule(LoadingStore);

@Component({
    components: {}
})
export default class CrmTypeSelectList extends Mixins(LocaleMixin) {
    /**
     * Whether or not the select list should hide hints and errors.
     */
    @Prop({ default: '' }) readonly classStyles!: string;
    /**
     * Whether or not the select list should hide hints and errors.
     */
    @Prop({ default: false }) readonly dense!: boolean;
    /**
     * Whether or not the select list should hide hints and errors.
     */
    @Prop({ default: 'auto' }) readonly hideDetails!: boolean | string;
    /**
     * Whether this is clearable
     */
    @Prop({ type: Boolean, default: false }) readonly isClearable!: boolean;
    /**
     * Whether this is for a new entity.
     */
    @Prop({ type: Boolean, default: false }) readonly isNew!: boolean;
    /**
     * Whether or not the select list is required.
     */
    @Prop({ default: false }) readonly isRequired!: boolean;
    /**
     * The options in the list.
     */
    @Prop({ type: Array, default: () => [] }) readonly items!: Array<CrmTypeOption>;
    /**
     * Is select list emphasized.
     */
    @Prop({ default: true }) readonly isEmphasized!: boolean;
    /**
     * The label for the select list.
     */
    @Prop({ type: String, required: true }) readonly label!: string;
    /**
     * The placeholder for the select list.
     */
    @Prop({ type: String }) readonly placeholder!: string;
    /**
     * The list to query the API for.
     */
    @Prop({ type: String }) readonly list!: CrmTypeList;
    /**
     * The loading key of the parent component.
     */
    @Prop({ type: String, default: '' }) readonly loadingKey!: string;
    /**
     * The validation rules.
     */
    @Prop({ default: () => [] }) readonly rules!: Array<Function | boolean | string>;
    /**
     * The value passed in.
     */
    @Prop() readonly value!: number | string | null;

    private options: Array<CrmTypeOption | { id: null; value: string; is_default?: boolean }> = [];
    private updatedEvent = EventTypes.UPDATED;

    get localValue() {
        return this.value;
    }

    set localValue(value) {
        this.$emit(EventTypes.INPUT, value);
    }

    get isGenderSelectList(): boolean {
        return this.list === CrmTypeList.GENDER;
    }

    get isPhoneSelectList(): boolean {
        return this.list === CrmTypeList.PHONE_TYPE;
    }

    private itemValue(item: CrmTypeOption) {
        if (this.isGenderSelectList || this.isPhoneSelectList) {
            return item.value;
        }
        return item.id;
    }

    /**
     * Watch when the options are set.
     */
    @Watch('items', { deep: true })
    private watchItems() {
        this.options = this.items;
    }

    /**
     * Watch when the options are set.
     */
    @Watch('options', { immediate: true, deep: true })
    private watchOptions() {
        if (this.options.length) {
            this.setDefault();
        }
    }

    async mounted() {
        if (!this.list) {
            this.options = this.items;
            return;
        }
        if (this.list) {
            if (this.loadingKey) {
                loadingStore.loadingIncrement(this.loadingKey);
            }
            await crmTypesStore.initAllList(this.list);
            const allListOptions = crmTypesStore.allListOptions(this.list);
            if (!this.isGenderSelectList && !this.isPhoneSelectList) {
                if (this.localValue) {
                    this.options = allListOptions.filter(option => option.id === this.localValue as number || (option.is_active && !option.is_hidden));
                } else {
                    this.options = allListOptions.filter(option => option.is_active && !option.is_hidden);
                }
            } else {
                this.options = allListOptions.filter(option => option.value === this.localValue as string || (option.is_active && !option.is_hidden));
            }
            if (this.loadingKey) {
                loadingStore.loadingDecrement(this.loadingKey);
            }
        }
    }

    /**
     * Set the default when no value is present.
     */
    private setDefault() {
        if (!this.localValue && this.isNew) {
            for (const option of this.options) {
                if (option.is_default) {
                    this.localValue = option.id;
                    break;
                }
            }
        }
    }

    updated() {
        this.$emit(EventTypes.UPDATED);
    }

}
