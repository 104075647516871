import { PendingFamilyRecording } from '@/communications/recordings/models/recording-models';
import { AbstractCreateDto, AbstractUpdateDto } from '@/models/abstract-dto';
import { CrmEntity, HateoasLink } from '@/models/base';
import { StatusLink } from '@/families/models/status';
import {
    Guardian,
    GuardianCreateDto,
    GuardianCreateDtoInterface,
    GuardianLink,
    GuardianUpdateDtoInterface
} from '@/families/models/guardian';
import { Child, ChildCreateDtoInterface, ChildPostDTO, ChildUpdateDtoInterface } from '@/families/models/child';
import { MarketingSubscriptions } from '@/marketing/models/marketing-model';
import { LinkedFamilies } from '@/families/models/linkReferTransfer';
import { CustomValues, CustomValuesUpdateDto } from '@/crm-types/custom-fields/custom-fields-types';
import { CenterLink } from '@/organizations/locations/models/center';
import { SimpleContact } from '@/families/models/contact';
import { TextThread } from '@/communications/messages/models/text';

export interface Family extends CrmEntity {
    is_active: boolean;
    status: StatusLink | null;
    exported: boolean;
    exported_date: string | null;
    created_date: string;
    added_date: string;
    inquiry_type: HateoasLink | null;
    source_type: HateoasLink | null;
    origin_type: HateoasLink | null;
    marketing_campaign: HateoasLink | null;
    marketing_campaigns: Array<HateoasLink> | null;
    do_not_auto_email: boolean;
    do_not_call: boolean;
    do_not_email: boolean;
    do_not_text: boolean;
    subscriptions: MarketingSubscriptions;
    comments: string | null;
    primary_guardian: Guardian;
    guardians: Array<Guardian>;
    contacts: Array<SimpleContact>;
    good_standing: boolean;
    children: Array<Child>;
    integrations: Array<FamilyIntegration>;
    custom_type_1: HateoasLink | null;
    custom_type_2: HateoasLink | null;
    custom_type_3: HateoasLink | null;
    custom_type_4: HateoasLink | null;
    custom_values: Array<CustomValues>;
    enrollment_rep?: HateoasLink | null;
    is_duplicate?: boolean;
    linked_families: Array<LinkedFamilies> | null;
    center: HateoasLink | null;
    family_score?: string | number | null;
    facebook_user_id?: string | number | null;
}

export interface BasicChild extends CrmEntity {
    first_name: string;
    last_name: string;
}

export interface BasicFamily extends CrmEntity {
    first_name: string;
    last_name: string;
    phone_number: string;
    center: CenterLink;
    children: Array<BasicChild>;
}

export interface PendingFamily extends Family {
    is_duplicate: boolean;
    is_refer: boolean;
    is_transfer: boolean;
    duplicates: Array<FamilyLink> | null;
    call_recordings: Array<PendingFamilyRecording>;
    texts: TextThread;
    refer_transfer_center: HateoasLink | null;
}

export enum PendingFamilyActions {
    ACCEPT = 'Accept',
    COMPARE = 'Compare',
    NEW_FAMILY = 'New Family',
    PLAY_RECORDING = 'Play Call Recording',
    REJECT = 'Reject',
    VIEW = 'View'
}

export interface PendingLeadTableItem extends CrmEntity {
    name: string;// name for sorting
    nameDisplay: string;// name for display
    center: string | undefined;
    email: string;
    phone: string;
    contact: string;// contact for sorting
    comments: string | null;
    contactDisplay: string;
    isDuplicate: boolean;
    duplicates: Array<FamilyLink> | null;
    action: Array<string>;
    call_recordings: Array<PendingFamilyRecording>;
    texts: TextThread;
}

export interface FamilyLink extends HateoasLink {
    values: { name: string };
}

export interface FamilyCreateDtoInterface extends AbstractCreateDto {
    is_active: boolean;
    inquiry_type: number;
    source_type: number;
    added_date: string;
    primary_guardian: GuardianCreateDtoInterface | GuardianUpdateDtoInterface;
    children: Array<ChildCreateDtoInterface | ChildUpdateDtoInterface>;
    good_standing: boolean;
    do_not_call: boolean;
    do_not_email: boolean;
    do_not_text: boolean;
    comments?: string | null;
    marketing_campaign: number | Array<number> | null;
    custom_values?: Array<CustomValuesUpdateDto> | null;
    custom_type_1?: number | null;
    custom_type_2?: number | null;
    custom_type_3?: number | null;
    custom_type_4?: number | null;
    enrollment_rep?: number | null;
}

export class FamilyCreateDto implements AbstractCreateDto {
    id?: number;
    is_active = true;
    inquiry_type: number | null = null;
    source_type: number | null = null;
    primary_guardian = new GuardianCreateDto();
    children: Array<ChildPostDTO> = [];
    good_standing = true;
    do_not_call = false;
    do_not_email = false;
    do_not_text = false;
    marketing_campaign = null;
    comments = '';
    custom_values: Array<CustomValuesUpdateDto> = [];
}

export interface FamilyUpdateDtoInterface extends FamilyCreateDtoInterface, AbstractUpdateDto {
    id: number;
    status?: number;
}

// Interface to define search parameters for a family
export interface BasicFamilySearchParameters {
    queries: Array<string|number>;
    center_ids?: Array<string|number>;
    org_id?: number;
    skip_children?: boolean;
    skip_contacts?: boolean;
}

export interface FamilySearchParameters {
    guardian_first_name?: string;
    guardian_last_name?: string;
    child_first_name?: string;
    child_last_name?: string;
    phone_number?: string;
    email_address?: string;
    center_ids?: string;
    org_id?: number;
    query?: string;
    queries?: Array<string>;
    duplicate_check?: boolean;
}

export enum BasicFamilySearchResultType {
    LEAD = 'lead',
    CHILD = 'child',
    CONTACT = 'contact'
}

export interface BasicFamilySearchResult {
    id: number;
    type: BasicFamilySearchResultType;
    lead_id: number;
    lead_full_name: string;
    lead_first_name: string;
    lead_last_name: string;
    lead_phone: string | null;
    lead_email: string | null;
    lead_city: string | null;
    related_centers: Array<any> | null;
    avatar_url: string | null;
    center_code: string;
    center_name: string;
    center_id: number;
    cms_id: string | null;
    cms_url: string | null;
    do_not_call: boolean;
    do_not_email: boolean;
    do_not_text: boolean;
    status_code: string | null;

    // Child fields.
    child_id?: number;
    child_full_name?: string;
    child_first_name?: string;
    child_last_name?: string;
    child_dob?: string | null;
    child_classroom?: string | null;

    // Contact fields.
    contact_id?: number;
    contact_full_name?: string;
    contact_first_name?: string;
    contact_last_name?: string;
    contact_phone?: string | null;
    contact_email?: string | null;
    contact_is_guardian?: boolean;
    contact_is_family?: boolean;
    contact_is_emergency_contact?: boolean;
    contact_is_authorized_pickup?: boolean;
}

export interface FamilySearchResults {
    results: Array<Family>;
}

export interface FamilyComparison {
    original: FamilyUpdateDtoInterface;
    current: FamilyUpdateDtoInterface;
    action?: PotentialDuplicateLeadActions;
    mergeIntoFamilyId?: number;
}

export interface AcceptFamilyEventPayload {
    familiesToLink?: Record<number, boolean>;
    centerId?: number;
    hasCall?: boolean;
    acceptedInDuplicateModal?: boolean;
}

export interface RejectFamilyInterface {
    merge_to_family_id?: number | null;
    reason?: number;
}

export enum PotentialDuplicateLeadActions {
    KEEP = 'keep',
    LINK = 'link',
    REJECT = 'reject'
}

export interface LastViewedDateTimeInfo {
    date_time: string;
}

export interface FamilyLastViewedInfo {
    primary_guardian: GuardianLink;
    children: Array<HateoasLink>;
    center: HateoasLink;
    last_viewed: LastViewedDateTimeInfo;
}

export interface FamilyIntegration {
    id: number | string;
    integration: HateoasLink;
    integration_partner_id: number;
}

export interface BulkDeleteFamiliesDto {
    excluded_families: Array<number> | null;
    included_families: Array<number> | null;
    org_id: number;
}

export interface FamiliesImportFileDto {
    file: string;
    filename: string;
}

// This needs to match the nested structure path of the FamilyUpdateDtoInterface
export enum FamilyFieldPaths {
    FIRST_NAME = 'primary_guardian.first_name',
    LAST_NAME = 'primary_guardian.last_name',
    ADDRESS1 = 'primary_guardian.address.address1',
    PHONE_NUMBER = 'primary_guardian.primary_phone.number_e164',
    EMAIL = 'primary_guardian.email',
    INQUIRY_TYPE = 'inquiry_type',
    SOURCE_TYPE = 'source_type',
    STATUS = 'status',
}

export enum PotentialDuplicateActionConstants {
    SAVE = 'save',
    MERGE = 'merge',
    LINK = 'link',
    HIDE = 'hide',
    REJECT = 'reject'
}
