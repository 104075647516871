import Vue, { VNode } from 'vue';
import App from './App.vue';
import store from './store';
import router, { redirectRouter } from './router';
import { library as FontAwesomeLibrary } from '@fortawesome/fontawesome-svg-core';
import { fas as FontAwesomeSolid } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon, FontAwesomeLayers, FontAwesomeLayersText } from '@fortawesome/vue-fontawesome';
import { fad as FontAwesomeDualtone } from '@fortawesome/pro-duotone-svg-icons';
import vuetify from './plugins/vuetify';
import VueSweetalert2 from 'vue-sweetalert2';
import VueIntercom from '@mathieustan/vue-intercom';
import HighchartsVue from 'highcharts-vue';
import Highcharts from 'highcharts';
import ExportingHighcharts from 'highcharts/modules/exporting';
import offlineExporting from 'highcharts/modules/offline-exporting';
import accessibility from 'highcharts/modules/accessibility';
import highchartsMore from 'highcharts/highcharts-more';
import solidGauge from 'highcharts/modules/solid-gauge';
import stockInit from 'highcharts/modules/stock';

import 'typeface-roboto/index.css';
import '@mdi/font/css/materialdesignicons.css';
import 'sweetalert2/dist/sweetalert2.min.css';
import 'material-design-icons-iconfont/dist/material-design-icons.css';
import { BaseComponentsPlugin } from '@/plugins/base-components';
import i18n from './i18n';
import { daysAgoFromToday } from '@/core/filters';

import VueDOMPurifyHTML from 'vue-dompurify-html';
import { intercomAppId, rollbarEnv, rollbarToken, timeoutMinutes, googleMapsKey } from '@/core/env-vars';
import VueFriendlyIframe from 'vue-friendly-iframe';

import IdleVue from 'idle-vue';
import { Component } from 'vue-property-decorator';
import Rollbar from 'rollbar';

import Sortable, { SortableEvent } from 'sortablejs';

import * as GmapVue from 'gmap-vue';

import * as sugar from 'sugar';
import cloneDeep from 'lodash/cloneDeep';

import PopperVue from '@soldeplata/popper-vue';

// make sure NZ dates validate/parse correctly in sugarjs
sugar.Date.addLocale('en-NZ', cloneDeep(sugar.Date.getLocale('en-AU')));

Vue.prototype.$rollbar = new Rollbar({
    accessToken: rollbarToken,
    captureUncaught: true,
    captureUnhandledRejections: true,
    environment: rollbarEnv
});

Vue.config.errorHandler = (err, vm) => {
    // this pops up when navigating before everything loads
    if (err.message.search(/this\.someMutation/)) {
        return;
    }
    vm.$rollbar.error(err);
    throw err; // rethrow
};

// instantiate fontawesome icons
FontAwesomeLibrary.add(FontAwesomeSolid);
FontAwesomeLibrary.add(FontAwesomeDualtone);
Vue.component('font-awesome-icon', FontAwesomeIcon); // register vue component
Vue.component('font-awesome-layers', FontAwesomeLayers);
Vue.component('font-awesome-layers-text', FontAwesomeLayersText);

// vue configuration
Vue.config.productionTip = false;

// any global options for sweetalert2 can be set here
const swalOptions = {
    showCloseButton: true
};
Vue.use(VueSweetalert2, swalOptions);

Vue.use(VueIntercom, { appId: intercomAppId });

Vue.use(BaseComponentsPlugin);

Vue.use(IdleVue, {
    eventEmitter: new Vue(),
    store,
    idleTime: timeoutMinutes * 60 * 1000
});

Vue.use(PopperVue);

// https://github.com/xkjyeah/vue-google-maps/
Vue.use(GmapVue, {
    load: {
        key: googleMapsKey,
        libraries: 'places' // This is required if you use the Autocomplete plugin
        // OR: libraries: 'places,drawing'
        // OR: libraries: 'places,drawing,visualization'
        // (as you require)

        // If you want to set the version, you can do so:
        // v: '3.26',
    },

    // If you intend to programmatically custom event listener code
    // (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
    // instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
    // you might need to turn this on.
    // autobindAllEvents: false,
    autobindAllEvents: true,

    // If you want to manually install components, e.g.
    // import {GmapMarker} from 'vue2-google-maps/src/components/marker'
    // Vue.component('GmapMarker', GmapMarker)
    // then set installComponents to 'false'.
    // If you want to automatically install all the components this property must be set to 'true':
    installComponents: true
});

Component.registerHooks([
    'onIdle',
    'onActive'
]);

accessibility(Highcharts);
highchartsMore(Highcharts);
solidGauge(Highcharts);
stockInit(Highcharts);

ExportingHighcharts(Highcharts);
offlineExporting(Highcharts);

// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore-next-line
Highcharts.wrap(Highcharts.Renderer.prototype, 'label', function(proceed: any, str: any, x: any, y: any, shape: any, anchorX: any, anchorY: any, useHTML: boolean) {
    if (/class="fa/.test(str)) {
        useHTML = true;
    }

    // Run original proceed method
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore-next-line
    return proceed.call(this, str, x, y, shape, anchorX, anchorY, useHTML);
});

Vue.use(HighchartsVue);

// filters
Vue.filter('daysAgoFromToday', daysAgoFromToday);

Vue.use(VueDOMPurifyHTML, {
    namedConfigurations: {
        noimg: {
            FORBID_TAGS: ['img']
        },
        text: {
            ALLOWED_TAGS: []
        }
    }
});

Vue.use(VueFriendlyIframe);

// based on https://github.com/vuetifyjs/vuetify/issues/2234
Vue.directive('sortableDataTable', {
    bind(el, binding, vnode: VNode) {
        const options = {
            animation: 150,
            handle: '.drag-handle',
            onUpdate: function(event: SortableEvent) {
                (vnode as any).child.$emit('sorted', event);
            }
        };
        Sortable.create(el.getElementsByTagName('tbody')[0], options);
    }
});

// mount the root component
if (window.location.pathname.startsWith('/nylas-calendar-code') || window.location.pathname.startsWith('/nylas-code')) {
    new Vue({
        store,
        router: redirectRouter,
        vuetify,
        i18n,
        render: h => h(App)
    }).$mount('#app');
} else {
    new Vue({
        store,
        router,
        vuetify,
        i18n,
        render: h => h(App)
    }).$mount('#app');
}

// Make the store available to Cypress
// eslint-disable-next-line @typescript-eslint/ban-ts-ignore
// @ts-ignore
if (window.Cypress) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-ignore
    // @ts-ignore
    window.store = store;
}
