import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { StoreModuleTypes } from '@/constants/store-constants';
import store from '@/store/index';

export interface StorePromise {
    promise: Promise<any>;
    datetime: Date;
}

export const storePromiseTtl = 30;

@Module({
    namespaced: true,
    dynamic: true,
    store,
    name: StoreModuleTypes.PROMISES
})
export class PromiseStore<T extends any> extends VuexModule {
    promisesMap: Map<string, StorePromise> = new Map();

    /**
     * Call this in store inits to avoid duplicated API calls. See centers-store for
     * examples
     *
     * @param init
     * @protected
     */
    @Action
    public async initPromise(init: { hash: string; closure: Function; force: boolean }): Promise<any> {
        if (!init.force) {
            const stored = this.promises(init.hash);
            if (stored) {
                return stored;
            }
        }

        const promise = init.closure();
        this.context.commit('setPromise', { hash: init.hash, promise: promise });
        return promise;
    }

    /**
     * helper for initPromise
     *
     * @protected
     */
    protected get promises() {
        return (hash: string) => {
            if (this.promisesMap.has(hash)) {
                const stored = this.promisesMap.get(hash)!;
                if ((new Date()).getTime() - stored.datetime.getTime() < (storePromiseTtl * 1000)) {
                    return stored.promise;
                }
            }

            return null;
        };
    }

    /**
     * Helper for initPromise.
     *
     * @param update
     * @protected
     */
    @Mutation
    protected setPromise(update: { hash: string; promise: Promise<any> }) {
        this.promisesMap.set(update.hash, {
            promise: update.promise,
            datetime: new Date()
        });
    }
}
