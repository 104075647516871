import {
    Family, FamilyCreateDto,
    FamilyUpdateDtoInterface, PendingFamily, PotentialDuplicateActionConstants
} from '@/families/models/family';
import { getModule } from 'vuex-module-decorators';
import { CentersStore } from '@/organizations/locations/stores/centers-store';
import { FamilyMapper } from '@/families/mappers/family-mapper';
import { CrmTypeList, CrmTypeOption } from '@/crm-types/models/crm-type';
import { CrmTypesStore } from '@/crm-types/store/crm-types-store';
import { Child, ChildCreateDtoInterface, ChildUpdateDtoInterface } from '@/families/models/child';
import {
    buildFamilyEntries,
    generateDifferencesChildrenMap,
    generateDifferencesFamilyMap, getCenterNames,
    groupChildrenByFullName
} from '@/families/potential-duplicate-utils';
import { StatusChangeInterface } from '@/families/models/status';
import { ChangeStatus } from '@/families/change-status';
import { FamiliesRepository } from '@/families/repositories/families-repository';
import { ChildrenRepository } from '@/families/repositories/children-repository';
import { StatusChangeRepository } from '@/families/repositories/status-change-repository';
import { StatusChangesStore } from '@/families/store/status-changes-store';
import { PendingFamilyService } from '@/families/pending-family-service';
import { EnrollmentSchedule, EnrollmentUpdateDtoInterface } from '@/families/models/enrollment';
import { FeaturesStore } from '@/features/features-store';
import { FeatureConstants } from '@/features/feature-constants';
import { EnrollmentsRepository } from '@/families/repositories/enrollments-repository';
import cloneDeep from 'lodash/cloneDeep';
import { StatusesStore } from '@/families/store/statuses-store';

export interface FromAddFamilyPayload {
    familyDto: FamilyUpdateDtoInterface | FamilyCreateDto;
    pendingFamily: PendingFamily | null;
    childrenStatusUpdates: Array<StatusChangeInterface | null>;
    classrooms: Array<number | null>;
    schedules: Array<EnrollmentSchedule | undefined>;
}

export interface ChildEntry {
    childDto: ChildUpdateDtoInterface | ChildCreateDtoInterface;
    child: Child | null;
    family: Family | PendingFamily | null;
    availableStatuses: Array<number>;
    statusUpdates: StatusChangeInterface | null;
    classroom?: number;
    schedule?: EnrollmentSchedule;
}

export interface FamilyEntry {
    familyDto: FamilyUpdateDtoInterface | FamilyCreateDto;
    family: Family | PendingFamily | null;
    statusUpdates: StatusChangeInterface | null;
}

export interface PotentialDuplicateActionOption {
    text: string;
    value: string;
}

export interface FieldOverwrite {
    fieldPath: string;
    oldValue: any;
    mergedValue: any;
}

export interface MergeLayer {
    sourceId: number;
    familyOverwrites: Array<FieldOverwrite>;
    childrenOverwrites: Record<string, Array<FieldOverwrite>>;
}

export default class PotentialDuplicateService {
    private featuresStore = getModule(FeaturesStore);
    private centerStore = getModule(CentersStore);
    private crmTypesStore = getModule(CrmTypesStore);
    private centerNames: Array<string> = [];
    private currentFamilyEntries: Array<FamilyEntry> = [];
    private familyMapper = new FamilyMapper();
    private inquiryTypes: Array<CrmTypeOption> = [];
    private sourceTypes: Array<CrmTypeOption> = [];
    private currentChildrenGroupedByName: Record<string, Array<ChildEntry>> = {};
    private familyDifferencesRecord: Record<string, boolean> = {};
    private childrenDifferencesArray: Array<{ name: string; differences: Record<string, boolean> }> = [];
    private statusIdsForChildlessFamilies: Array<number> = [];
    private changeStatusUtil = new ChangeStatus();
    private familiesRepository = new FamiliesRepository();
    private childrenRepository = new ChildrenRepository();
    private statusChangeRepository = new StatusChangeRepository();
    private enrollmentsRepository = new EnrollmentsRepository()
    private statusChangesStore = getModule(StatusChangesStore);
    private pendingFamilyService = new PendingFamilyService();
    private addFamilyPayload: FromAddFamilyPayload | null = null;
    private statusStores = getModule(StatusesStore);

    get isClassroomsFeatureEnabled(): boolean {
        return this.featuresStore.isFeatureEnabled(FeatureConstants.CLASSROOMS);
    }

    get inquiryTypesOptions() {
        return this.inquiryTypes;
    }

    get sourceTypesOptions() {
        return this.sourceTypes;
    }

    get statusesForChildlessFamilies() {
        return this.statusIdsForChildlessFamilies;
    }

    get centerRows() {
        return this.centerNames;
    }

    get familyEntries() {
        return this.currentFamilyEntries;
    }

    get childrenGrouped() {
        return this.currentChildrenGroupedByName;
    }

    get familyDifferences() {
        return this.familyDifferencesRecord;
    }

    get childrenDifferences() {
        return this.childrenDifferencesArray;
    }

    public updateFamilyEntries(value: Array<FamilyEntry>) {
        this.currentFamilyEntries = value;
    }

    public updateChildrenGroupedByName(value: Record<string, Array<ChildEntry>>) {
        this.currentChildrenGroupedByName = value;
    }

    async init(families: Array<Family | PendingFamily | null>, fromAddFamilyPayload: FromAddFamilyPayload | null = null) {
        await this.featuresStore.init();
        await this.centerStore.initAccessibleCenters();
        await this.statusStores.init();
        this.currentFamilyEntries = [];
        this.centerNames = [];
        this.currentChildrenGroupedByName = {};
        this.familyDifferencesRecord = {};
        this.childrenDifferencesArray = [];
        this.addFamilyPayload = fromAddFamilyPayload;

        this.currentFamilyEntries = buildFamilyEntries(families, fromAddFamilyPayload);
        // Build centerNames to match the order and length of currentFamilyEntries.
        this.centerNames = getCenterNames(this.currentFamilyEntries, this.centerStore.storedAccessibleCenters);
        const currentFamiliesDto = this.currentFamilyEntries.map(familyEntry => familyEntry.familyDto);
        this.currentChildrenGroupedByName = Object.fromEntries(groupChildrenByFullName(currentFamiliesDto, families, fromAddFamilyPayload).entries());
        this.childrenDifferencesArray = Object.keys(this.currentChildrenGroupedByName).map(name => ({
            name,
            differences: generateDifferencesChildrenMap(
                this.currentChildrenGroupedByName[name].map(childEntry => childEntry.childDto),
                this.currentChildrenGroupedByName[name].map(childEntry => childEntry.statusUpdates)
            )
        }));
        this.familyDifferencesRecord = generateDifferencesFamilyMap(currentFamiliesDto);
    }

    async setupSelectListOptions() {
        const familyInquiryPromise = this.crmTypesStore.initList(CrmTypeList.FAMILY_INQUIRY);
        const familySourcePromise = this.crmTypesStore.initList(CrmTypeList.FAMILY_SOURCE);
        await Promise.all([familyInquiryPromise, familySourcePromise]);

        this.inquiryTypes = [];
        this.sourceTypes = [];
        this.statusIdsForChildlessFamilies = [];

        const inquiryTypesSet: Set <number> = new Set();
        const sourceTypesSet: Set <number> = new Set();
        const statusIdsForChildlessFamiliesSet: Set<number> = new Set();
        for (const familyEntry of this.familyEntries) {
            if (familyEntry.familyDto.inquiry_type) {
                inquiryTypesSet.add(familyEntry.familyDto.inquiry_type);
            }
            if (familyEntry.familyDto.source_type) {
                sourceTypesSet.add(familyEntry.familyDto.source_type);
            }
            if (familyEntry.familyDto.children.length === 0) {
                let currentStatus = 1; // Default
                if ('status' in familyEntry.familyDto && familyEntry.familyDto.status) {
                    currentStatus = familyEntry.familyDto.status;
                } else if (familyEntry.familyDto.primary_guardian.status) {
                    currentStatus = familyEntry.familyDto.primary_guardian.status;
                }
                statusIdsForChildlessFamiliesSet.add(currentStatus);
            }
        }
        this.inquiryTypes = this.crmTypesStore.listOptions(CrmTypeList.FAMILY_INQUIRY).filter(option => inquiryTypesSet.has(option.id));
        this.sourceTypes = this.crmTypesStore.listOptions(CrmTypeList.FAMILY_SOURCE).filter(option => sourceTypesSet.has(option.id));
        this.statusIdsForChildlessFamilies = Array.from(statusIdsForChildlessFamiliesSet);
    }

    private async updateEnrollment(centerId: number, newChild: Child, childEntry: ChildEntry) {
        const enrollmentUpdate: EnrollmentUpdateDtoInterface = {
            id: newChild.enrollments[0].id,
            center_id: centerId,
            child_id: newChild.id
        };
        if (this.isClassroomsFeatureEnabled && childEntry.classroom) {
            enrollmentUpdate.classroom_id = childEntry.classroom;
        }
        if (childEntry.schedule) {
            enrollmentUpdate.schedule = childEntry.schedule;
        }
        await this.enrollmentsRepository.update(enrollmentUpdate);
    }

    async save(selectedActions: Record<number, PotentialDuplicateActionOption | null>): Promise<number | null> {
        let result = null;
        if (this.addFamilyPayload && this.addFamilyPayload.pendingFamily === null) {
            // If there is an add family payload and the pending family is null, meaning a new family will be created and we need to return the new family id
            result = await this.processSavingWithAddFamilyModalPayload(selectedActions);
        } else {
            await this.processSaving(selectedActions);
        }
        return result;
    }

    async processSaving(selectedActions: Record<number, PotentialDuplicateActionOption | null>) {
        await this.processFamilyAndChildrenUpdates(selectedActions);
        await this.processFamilyPostActions(selectedActions);
    }

    private async processFamilyAndChildrenUpdates(selectedActions: Record<number, PotentialDuplicateActionOption | null>) {
        for (const [i, familyEntry] of this.currentFamilyEntries.entries()) {
            const currentFamilyId = familyEntry.familyDto.id!;
            const selectedAction = selectedActions[currentFamilyId];
            if (!selectedAction) continue;

            const [actionType] = selectedAction.value.split('_');

            // If a pending family is being saved or linked, accept the family first.
            if (
                familyEntry.family &&
                familyEntry.family.status === null &&
                (actionType === PotentialDuplicateActionConstants.LINK || actionType === PotentialDuplicateActionConstants.SAVE)
            ) {
                await this.pendingFamilyService.acceptFamily(currentFamilyId);
            }

            await this.familiesRepository.update(familyEntry.familyDto as FamilyUpdateDtoInterface);

            // Process each grouped child entry using the same index.
            for (const [, childEntries] of Object.entries(this.currentChildrenGroupedByName)) {
                const currentChildEntry = childEntries[i];
                if (!currentChildEntry) continue;

                const currentChildId = currentChildEntry.childDto.id;
                if (currentChildId) {
                    if (currentChildId > 0) {
                        // Existing child.
                        await this.childrenRepository.update(currentFamilyId, currentChildEntry.childDto as ChildUpdateDtoInterface);
                    } else if (
                        currentChildEntry.childDto.first_name &&
                        currentChildEntry.childDto.last_name &&
                        currentChildEntry.statusUpdates &&
                        currentChildEntry.statusUpdates.status > 0
                    ) {
                        const newChildDto = currentChildEntry.childDto as ChildCreateDtoInterface;
                        delete newChildDto.id;
                        newChildDto.status = 1; // Set New Family status.
                        const newChild = (await this.childrenRepository.create(currentFamilyId, newChildDto))[0];

                        // Update statusUpdates with the new child id.
                        currentChildEntry.statusUpdates.child_id = newChild.id;

                        const centerId = familyEntry.familyDto.primary_guardian.center_id;
                        if (centerId && (currentChildEntry.schedule || currentChildEntry.classroom)) {
                            await this.updateEnrollment(centerId, newChild, currentChildEntry);
                        }
                    }
                }
            }
        }
    }

    private async processFamilyPostActions(selectedActions: Record<number, PotentialDuplicateActionOption | null>) {
        for (const [i, familyEntry] of this.currentFamilyEntries.entries()) {
            const currentFamilyId = familyEntry.familyDto.id!;
            const selectedAction = selectedActions[currentFamilyId];
            if (!selectedAction) continue;

            const [actionType, targetFamilyString] = selectedAction.value.split('_');
            const targetFamilyId = parseInt(targetFamilyString, 10);

            // Handle reject or merge actions.
            if (actionType === PotentialDuplicateActionConstants.REJECT) {
                await this.familiesRepository.rejectFamily(currentFamilyId, {});
                continue;
            }
            if (actionType === PotentialDuplicateActionConstants.MERGE) {
                await this.familiesRepository.rejectFamily(currentFamilyId, { merge_to_family_id: targetFamilyId });
                continue;
            }
            // Handle linking.
            if (actionType === PotentialDuplicateActionConstants.LINK) {
                await this.familiesRepository.linkFamily(targetFamilyId, { family_id: currentFamilyId });
            }

            // Update family status if needed.
            if (familyEntry.statusUpdates && familyEntry.statusUpdates.family_id > 0) {
                await this.statusChangeRepository.changeStatus(familyEntry.statusUpdates, true);
                this.statusChangesStore.clear(currentFamilyId);
            }

            // Process status changes for child entries.
            for (const [, childEntries] of Object.entries(this.currentChildrenGroupedByName)) {
                const currentChildEntry = childEntries[i];
                if (
                    currentChildEntry &&
                    currentChildEntry.statusUpdates &&
                    currentChildEntry.statusUpdates.status > 0 &&
                    currentChildEntry.statusUpdates.child_id &&
                    currentChildEntry.statusUpdates.child_id > 0
                ) {
                    await this.statusChangeRepository.changeStatus(currentChildEntry.statusUpdates, true);
                    if (currentChildEntry.childDto.id) {
                        this.statusChangesStore.clear(currentChildEntry.childDto.id);
                    }
                }
            }
        }
    }

    async processSavingWithAddFamilyModalPayload(selectedActions: Record<number, PotentialDuplicateActionOption | null>): Promise<number> {
        const primaryFamilyId = await this.processPrimaryFamilyAndChildrenCreation(selectedActions);
        await this.processOtherFamilyAndChildrenUpdates(selectedActions);
        await this.processFamilyPostActionsWithPrimaryId(selectedActions, primaryFamilyId);
        return primaryFamilyId;
    }

    private async processPrimaryFamilyAndChildrenCreation(selectedActions: Record<number, PotentialDuplicateActionOption | null>): Promise<number> {
        const primaryEntry = this.currentFamilyEntries[0];
        let primaryFamilyId = primaryEntry.familyDto.id!;
        const primaryAction = selectedActions[primaryFamilyId];
        const isAddingFamily = this.addFamilyPayload && this.addFamilyPayload.pendingFamily === null;

        if (primaryAction && isAddingFamily) {
            const [actionType] = primaryAction.value.split('_');
            if (
                actionType !== PotentialDuplicateActionConstants.REJECT &&
                actionType !== PotentialDuplicateActionConstants.MERGE
            ) {
                const dto = cloneDeep(primaryEntry.familyDto);
                // Remove existing identifiers before creating a new family.
                delete dto.id;
                delete dto.children;
                const newFamily = await this.familiesRepository.create(dto as FamilyCreateDto);
                primaryFamilyId = newFamily.id;
                if (primaryEntry.statusUpdates) {
                    primaryEntry.statusUpdates.family_id = newFamily.id;
                }
            }

            // Process primary family children.
            for (const [, childEntries] of Object.entries(this.currentChildrenGroupedByName)) {
                const primaryChildEntry = childEntries[0];
                if (!primaryChildEntry) continue;

                const childDto = primaryChildEntry.childDto;
                const childId = childDto.id;
                if (childId && childDto.first_name && childDto.last_name && primaryChildEntry.statusUpdates && primaryChildEntry.statusUpdates.status > 0) {
                    // Create a new child.
                    const newChildDto = { ...childDto } as ChildCreateDtoInterface;
                    delete newChildDto.id;
                    newChildDto.status = 1; // New Family status.
                    const newChild = (await this.childrenRepository.create(primaryFamilyId, newChildDto))[0];
                    primaryChildEntry.statusUpdates.child_id = newChild.id;
                    primaryChildEntry.statusUpdates.family_id = primaryFamilyId;

                    const centerId = primaryEntry.familyDto.primary_guardian.center_id;
                    if (centerId && (primaryChildEntry.schedule || primaryChildEntry.classroom)) {
                        await this.updateEnrollment(centerId, newChild, primaryChildEntry);
                    }
                }
            }
        }

        return primaryFamilyId;
    }

    private async processOtherFamilyAndChildrenUpdates(selectedActions: Record<number, PotentialDuplicateActionOption | null>): Promise<void> {
        for (let i = 1; i < this.currentFamilyEntries.length; i++) {
            const familyEntry = this.currentFamilyEntries[i];
            const familyId = familyEntry.familyDto.id!;
            const action = selectedActions[familyId];

            if (action) {
                const [actionType] = action.value.split('_');
                if (
                    familyEntry.family &&
                    familyEntry.family.status === null &&
                    (actionType === PotentialDuplicateActionConstants.LINK || actionType === PotentialDuplicateActionConstants.SAVE)
                ) {
                    await this.pendingFamilyService.acceptFamily(familyId);
                }
                await this.familiesRepository.update(familyEntry.familyDto as FamilyUpdateDtoInterface);
            }

            // Process children for this family entry.
            for (const [, childEntries] of Object.entries(this.currentChildrenGroupedByName)) {
                const childEntry = childEntries[i];
                if (!childEntry) continue;

                const childDto = childEntry.childDto;
                const childId = childDto.id;
                if (childId) {
                    if (childId > 0) {
                        await this.childrenRepository.update(familyId, childDto as ChildUpdateDtoInterface);
                    } else if (childDto.first_name && childDto.last_name && childEntry.statusUpdates && childEntry.statusUpdates.status > 0) {
                        const newChildDto = { ...childDto } as ChildCreateDtoInterface;
                        delete newChildDto.id;
                        newChildDto.status = 1;
                        const newChild = (await this.childrenRepository.create(familyId, newChildDto))[0];
                        childEntry.statusUpdates.child_id = newChild.id;
                    }
                }
            }
        }
    }

    private async processFamilyPostActionsWithPrimaryId(
        selectedActions: Record<number, PotentialDuplicateActionOption | null>,
        primaryFamilyId: number
    ): Promise<void> {
        for (const [i, familyEntry] of this.currentFamilyEntries.entries()) {
            const currentFamilyId = familyEntry.familyDto.id!;
            const selectedAction = selectedActions[currentFamilyId];
            if (!selectedAction) continue;

            const [actionType, targetFamilyString] = selectedAction.value.split('_');
            const targetFamilyId =
            parseInt(targetFamilyString, 10) === -1 ? primaryFamilyId : parseInt(targetFamilyString, 10);

            // Handle rejection and merge actions (only for non-primary families).
            if (actionType === PotentialDuplicateActionConstants.REJECT && i > 0) {
                await this.familiesRepository.rejectFamily(currentFamilyId, {});
                continue;
            }
            if (actionType === PotentialDuplicateActionConstants.MERGE && i > 0) {
                await this.familiesRepository.rejectFamily(currentFamilyId, { merge_to_family_id: targetFamilyId });
                continue;
            }
            // Handle linking.
            if (actionType === PotentialDuplicateActionConstants.LINK) {
                await this.familiesRepository.linkFamily(targetFamilyId, { family_id: currentFamilyId === -1 ? primaryFamilyId : currentFamilyId });
            }

            // Update family status.
            if (familyEntry.statusUpdates && familyEntry.statusUpdates.family_id > 0) {
                await this.statusChangeRepository.changeStatus(familyEntry.statusUpdates, true);
                this.statusChangesStore.clear(familyEntry.familyDto.id!);
            }

            for (const [, childEntries] of Object.entries(this.currentChildrenGroupedByName)) {
                const currentChildEntry = childEntries[i];
                if (
                    currentChildEntry &&
                    currentChildEntry.statusUpdates &&
                    currentChildEntry.statusUpdates.status > 0 &&
                    currentChildEntry.statusUpdates.child_id &&
                    currentChildEntry.statusUpdates.child_id > 0 &&
                    currentChildEntry.statusUpdates.family_id &&
                    currentChildEntry.statusUpdates.family_id > 0
                ) {
                    await this.statusChangeRepository.changeStatus(currentChildEntry.statusUpdates, true);
                    if (currentChildEntry.childDto.id) {
                        this.statusChangesStore.clear(currentChildEntry.childDto.id);
                    }
                }
            }
        }
    }

}
