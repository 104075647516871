import { CrmApiElement, HateoasLink } from '@/models/base';

export const nylasStoredEvent = 'nylasStored';
export const nylasStoredStartEvent = 'nylasStoredStart';
export const nylasCalendarSelectEvent = 'nylasCalendarSelect';

export interface NylasAuthUrl extends CrmApiElement {
    url: string;
}

export interface NylasAuthCodeObject {
    code: string;
    state: string;
}

export interface NylasAuthCodeStoreDto {
    code: string;
    uuid: string;
    auth_token?: NylasAuthTokenObject | null;
    center_id?: number | null;
    calendar_id?: string | null;
    calendar_name?: string | null;
    show_calendar?: boolean;
}

export interface NylasAuthTokenObject {
    access_token: string;
    grant_id: string;
    account_id: string;
    email_address: string;
    provider: NylasProvider;
    token_type: string;
    id_token: string;
    expires_in: number;
    scope: string;
}

// Item returned from Nylas' calendar list API call.
export interface NylasCalendarListItem {
    id: string;
    name: string;
    account_id: string;
    description: string | null;
    is_primary: boolean;
    location: string | null;
    object: string | null;
    readonly: string | null;
    timezone: string | null;
}

// Calendar integration returned from our database.
export interface NylasCalendar {
    id: string;
    name: string;
    user: HateoasLink;
    centerId: number;
    is_public: boolean;
    is_revoked: boolean;
}

export interface NylasCalendarEvent {
    id: string;
    title: string;
    description: string;
    start_date_time: string;
    end_date_time: string;
}

export interface NylasCalendarResponse<T> {
    data: Array<T>;
    scope?: number;
}

export interface NylasEventFilter {
    starts_after?: string | null;
    ends_before?: string | null;
    user_id?: number | string | null;
    center_id?: number | string | null;
    org_id?: number | string | null;
    is_org_level_user?: boolean | null;
}

export type NylasProvider = 'eas' | 'exchange' | 'ews' | 'outlook' | 'hotmail' | 'yahoo' | 'imap' | 'gmail' | 'custom';

export interface NylasValidateEmailResponse extends CrmApiElement {
    valid: boolean;
}
