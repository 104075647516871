













































































































import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import type { Family } from '@/families/models/family';
import FamilyActivityTasks from '@/families/components/new/FamilyActivityTasks.vue';
import FamilyActivityCommunications from '@/families/components/new/FamilyActivityCommunications.vue';
import FamilyActivityHistory from '@/families/components/new/FamilyActivityHistory.vue';
import { Task } from '@/tasks/models/task-models';
import { TextMessage } from '@/communications/messages/models/text';
import { GrantResponse, EmailThread } from '@/communications/messages/models/email';
import { FacebookMessage } from '@/communications/messages/models/facebookMessage';
import { InContactEvent } from '@/integrations/models/in-contact-event';
import { getModule } from 'vuex-module-decorators';
import { LoadingStore } from '@/store/loading-store';
import { EmailsStore } from '@/communications/messages/stores/emails-store';
import { TextsStore } from '@/communications/messages/stores/texts-store';
import { FacebookMessagesStore } from '@/communications/messages/stores/facebook-messages-store';
import { MessageDirection, MessageLinkParams } from '@/communications/messages/models/message';
import { FeatureConstants } from '@/features/feature-constants';
import { FeaturesStore } from '@/features/features-store';
import { IncontactRepository } from '@/integrations/repositories/incontact-repository';
import { SixAgentStore } from '@/integrations/store/six-agent-store';
import { EventTypes } from '@/constants/event-type-constants';
import { HistoryTypes } from '@/constants/history-type-constants';
import { Recording } from '@/communications/recordings/models/recording-models';
import { RecordingsRepository } from '@/communications/recordings/repositories/recordings-repository';
import ActionItemsRepository, { ActionItemsParams } from '@/dashboards/repositories/action-items-repository';
import { ActionItem } from '@/dashboards/models/action-items-models';
import { UpdatesRepository } from '@/families/repositories/updates-repository';
import { NylasScope } from '@/integrations/nylas-constants';
import { AuthStore } from '@/store/auth-store';
import store from '@/store';
import { NylasRepository } from '@/integrations/repositories/nylas-repository';
import { StaffUtils } from '@/staff/staff-utils';
import { PermissionName } from '@/staff/models/user-permission-models';

const staffUtils = new StaffUtils();
const nylasRepo = new NylasRepository();
const loadingState = getModule(LoadingStore);
const emailsStore = getModule(EmailsStore);
const textsStore = getModule(TextsStore);
const facebookMessageStore = getModule(FacebookMessagesStore);
const featureStore = getModule(FeaturesStore);
const inContactRepo = new IncontactRepository();
const recordingsRepo = new RecordingsRepository();
const sixAgentState = getModule(SixAgentStore);
const actionItemsRepo = new ActionItemsRepository();
const historyRepo = new UpdatesRepository();
const authState = getModule(AuthStore, store);

type FamilyActivityTab = 'To Do' | 'Communications' | 'Future' | 'Comments' | 'History';

@Component({
    components: {
        FamilyActivityHistory,
        FamilyActivityCommunications,
        FamilyActivityTasks
    }
})
export default class FamilyHubActivity extends Mixins(LocaleMixin) {
    @Prop() family!: Family;
    @Prop() activityKey!: number;

    private isExpanded = true;
    private activeTab: FamilyActivityTab = 'To Do';
    private tasksTab: FamilyActivityTab = 'To Do';
    private commsTab: FamilyActivityTab = 'Communications';
    private futureTab: FamilyActivityTab = 'Future';
    private commentsTab: FamilyActivityTab = 'Comments';
    private historyTab: FamilyActivityTab = 'History';
    private loadingKey = FamilyHubActivity.name;
    private renderKey = 0;
    private texts: Array<TextMessage> = [];
    private emailThreads: Array<EmailThread> = [];
    private facebookMessages: Array<FacebookMessage> = [];
    private inContactEvents: Array<InContactEvent> | null = null;
    private meetings: Array<Task> = [];
    private recordings: Array<Recording> = [];
    private repliedEvent = EventTypes.REPLIED;
    private pendingEmailCancelledEvent = EventTypes.PENDING_EMAIL_CANCELLED;
    private pendingEmailUpdatedEvent = EventTypes.PENDING_EMAIL_UPDATED;
    private pendingTextCancelledEvent = EventTypes.PENDING_TEXT_CANCELLED;
    private pendingTextUpdatedEvent = EventTypes.PENDING_TEXT_UPDATED;
    private tasks: Array<ActionItem> = [];
    private futureTasks: Array<ActionItem> = [];
    private updatedEvent = EventTypes.UPDATED;
    private auditTrailTableType = HistoryTypes.AUDIT_TRAIL;
    private commentsTableType = HistoryTypes.COMMENTS;
    private commentsCount = 0;

    private tabs = [this.tasksTab, this.futureTab, this.commsTab, this.commentsTab, this.historyTab];

    @Watch('family', { immediate: true, deep: true })
    async familyChanged() {
        await this.updateFamilyData();
    }

    getTabHeaderCount(tab: FamilyActivityTab, numOnly = false): string | number {
        if (tab === this.tasksTab || tab === this.futureTab) {
            const tasks = tab === this.tasksTab ? this.tasks : this.futureTasks;
            return (numOnly && tab !== this.futureTab) ? tasks.length : `(${tasks.length})`;
        }

        if (tab === this.commsTab) {
            return numOnly ? this.unreadComms : `(${this.unreadComms})`;
        }

        if (tab === this.commentsTab) {
            return (numOnly && tab !== this.commentsTab) ? this.commentsCount : `(${this.commentsCount})`;
        }
        return '';
    }

    get hasCrmPlus(): boolean {
        return featureStore.isFeatureEnabled(FeatureConstants.CRM_PLUS_MODE);
    }

    get hasInContact(): boolean {
        return featureStore.isFeatureEnabled(FeatureConstants.INCONTACT);
    }

    private get sixAgentEventLogged() {
        return sixAgentState.eventLogged;
    }

    get unreadChatCount() {
        let count = 0;
        count = this.texts.filter(text => text.type === MessageDirection.INCOMING && !text.is_read && !text.is_dismissed).length ? 1 : 0;
        if (this.family.facebook_user_id && this.family.facebook_user_id !== '') {
            count += this.facebookMessages.filter(message => message.type === MessageDirection.INCOMING && !message.is_read && !message.is_dismissed).length ? 1 : 0;
        }
        return count;
    }

    get unreadEmailCount() {
        let count = 0;
        for (const thread of this.emailThreads) {
            count += thread.filter(message => message.type === MessageDirection.INCOMING && !message.is_read && !message.is_dismissed).length;
        }
        return count;
    }

    get unreadCallCount() {
        return this.recordings.filter(recording => recording.is_incoming && !recording.is_read && !recording.is_dismissed).length;
    }

    get unreadComms() {
        return this.unreadChatCount + this.unreadEmailCount + this.unreadCallCount;
    }

    // Life cycle.
    async mounted() {
        for (const param of Object.values(MessageLinkParams)) {
            if (this.$route.query[param]) {
                this.activeTab = this.commsTab;
            }
        }
    }

    @Watch('sixAgentEventLogged')
    private async sixAgentEventLoggedFlagChanged() {
        if (this.sixAgentEventLogged) {
            this.inContactEvents = await inContactRepo.getEvents(this.family.id);
        }
    }

    private newQuickText(newText: TextMessage) {
        this.texts.unshift(newText);
    }

    // Store and show the new outbound facebook message.
    newFacebookMessage(newFacebookMessage: FacebookMessage) {
        this.facebookMessages.unshift(newFacebookMessage);
    }

    async handleRouteUpdate() {
        if (authState.scope === NylasScope.email && authState.nylasReauthPopupCount === 0) {
            authState.setShowNylasReauthDialog(true);
            authState.setNylasScope(NylasScope.email);
            const currentRoute = this.$route;
            this.$router.push({
                path: currentRoute.path,
                query: {
                ...currentRoute.query,
                showPopup: 'true'
            }
        });
        }
    }

    private async updateFamilyData() {
        loadingState.loadingIncrement(this.loadingKey);
        if (!await staffUtils.getUserPermission(PermissionName.SettingsUsers)) {
            const response = await nylasRepo.getGrantIdExpired({
                is_org_level_user: await staffUtils.getUserPermission(PermissionName.SettingsUsers)
            });
            if (typeof response === 'object' && (response as GrantResponse)?.scope === NylasScope.email) {
                authState.setNylasScope(NylasScope.email);
                this.handleRouteUpdate();
            }
        }
        const textsPromise = textsStore.retrieveTextsForFamily(this.family.id);
        const emailsPromise = emailsStore.retrieveEmailsForFamily(this.family.id);
        const facebookMessagesPromise = facebookMessageStore.retrieveFacebookMessagesForFamily(this.family.id);
        const updateTasksPromise = this.updateTasks();
        await Promise.all([textsPromise, emailsPromise, facebookMessagesPromise, updateTasksPromise]);

        const comments = await historyRepo.getAllUpdatesForFamily(this.family.id, true);
        this.commentsCount = comments[0]?.changes?.length ?? 0;

        if (this.hasCrmPlus && this.hasInContact) {
            this.inContactEvents = await inContactRepo.getEvents(this.family.id);
        }
        this.recordings = await recordingsRepo.getRecordingsForFamily(this.family.id, true);
        this.texts = textsStore.stored;
        this.emailThreads = emailsStore.stored;
        this.facebookMessages = facebookMessageStore.stored;
        this.renderKey++;
        loadingState.loadingDecrement(this.loadingKey);
    }

    private async updateMessages() {
        loadingState.loadingIncrement(this.loadingKey);
        if (!await staffUtils.getUserPermission(PermissionName.SettingsUsers)) {
            const response = await nylasRepo.getGrantIdExpired({
                is_org_level_user: await staffUtils.getUserPermission(PermissionName.SettingsUsers)
            });
            if (typeof response === 'object' && (response as GrantResponse)?.scope === NylasScope.email) {
                authState.setNylasScope(NylasScope.email);
                this.handleRouteUpdate();
            }
        }
        const emailsPromise = emailsStore.retrieveEmailsForFamily(this.family.id);
        const textsPromise = textsStore.retrieveTextsForFamily(this.family.id);
        const updateTasksPromise = this.updateTasks();
        await emailsPromise;
        await textsPromise;
        await updateTasksPromise;
        this.emailThreads = emailsStore.stored;
        this.texts = textsStore.stored;
        this.facebookMessages = facebookMessageStore.stored;
        loadingState.loadingDecrement(this.loadingKey);
    }

    private async updateTasks() {
        loadingState.loadingIncrement(this.loadingKey);
        const itemsParams: ActionItemsParams = {
            family_id: this.family.id,
            task_dash_dates: ['past', 'today'],
            include_meetings: true
        };
        const resultsPromise = actionItemsRepo.getActionItems(itemsParams);
        itemsParams.task_dash_dates = ['future'];
        const futurePromise = actionItemsRepo.getActionItems(itemsParams);
        const results = await resultsPromise;
        const futureResults = await futurePromise;
        this.tasks = results.items;
        this.futureTasks = futureResults.items;
        loadingState.loadingDecrement(this.loadingKey);
    }

    updateFamily() {
        this.$emit(EventTypes.UPDATED);
    }
}
