









































































































































































































































































































































































































































































































import { EventTypes } from '@/constants/event-type-constants';
import { FamilyMapper } from '@/families/mappers/family-mapper';
import { ChildrenRepository } from '@/families/repositories/children-repository';
import { PendingLeadsRepository } from '@/families/repositories/lead/pending-leads-repository';
import cloneDeep from 'lodash/cloneDeep';
import { Component, Mixins, Prop, Ref, Watch } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import {
    AcceptFamilyEventPayload,
    Family,
    FamilyCreateDto,
    FamilyUpdateDtoInterface,
    PendingFamily
} from '@/families/models/family';
import { Center } from '@/organizations/locations/models/center';
import { Child, ChildCreateDtoInterface, ChildPostDTO } from '@/families/models/child';
import { CrmTypeList, CrmTypeOption, PhoneTypeIdentifiers } from '@/crm-types/models/crm-type';
import DynamicFieldEditor from '@/crm-types/components/DynamicFieldEditor.vue';
import CustomValuesEditor from '@/crm-types/custom-fields/components/CustomValuesEditor.vue';
import { LoadingStore } from '@/store/loading-store';
import { CrmTypesStore } from '@/crm-types/store/crm-types-store';
import { getModule } from 'vuex-module-decorators';
import { StatusesStore } from '@/families/store/statuses-store';
import { BaseStatuses } from '@/constants/status-constants';
import { Status, StatusChangeInterface } from '@/families/models/status';
import { FamiliesRepository } from '@/families/repositories/families-repository';
import { CentersStore } from '@/organizations/locations/stores/centers-store';
import { checkDuplicatesForNewFamily } from '@/families/search-families';
import { AppStateStore } from '@/store/app-state-store';
import { Relationship } from '@/families/models/relationship';
import { BasicValidationMixin } from '@/validation/basic-validation-mixin';
import {
    EnrollmentSchedule,
    EnrollmentUpdateDtoInterface,
    WaitlistDetailsUpdate,
    EnrollmentDateReasonWrite
} from '@/families/models/enrollment';
import { EnrollmentsRepository } from '@/families/repositories/enrollments-repository';
import {
    ChildFields,
    ChildScheduleFields,
    Field,
    FieldEntityType,
    GuardianFields
} from '@/crm-types/models/field-models';
import { FieldsStore } from '@/crm-types/store/fields-store';
import { generateChildFieldsList, generateGuardianFieldsList, sortFields } from '@/crm-types/field-utils';
import {
    getDefaultInquiryType,
    getDefaultPhoneType,
    getDefaultRelationship,
    getDefaultSource, isChildExportable
} from '@/families/families-utils';
import { isAfterNow } from '@/date-time/date-time-utils';
import PotentialDuplicate from '@/families/components/PotentialDuplicate.vue';
import store from '@/store';
import { VForm } from '@/types/types';
import { SixAgentStore } from '@/integrations/store/six-agent-store';
import { SixAgentUtils } from '@/integrations/six-agent-utils';
import { FeatureConstants } from '@/features/feature-constants';
import { FeaturesStore } from '@/features/features-store';
import { EnrollmentCenterSettingsStore } from '@/enrollment-center/store/enrollment-center-settings-store';
import { CustomField, CustomValuesUpdateDto } from '@/crm-types/custom-fields/custom-fields-types';
import { CustomFieldsStore } from '@/crm-types/custom-fields/stores/custom-fields-store';
import SelectClassPickList from '@/families/components/SelectClassPickList.vue';
import ChildScheduleEditor from '@/families/components/ChildScheduleEditor.vue';
import StatusChangeSelect from '@/families/components/StatusChangeSelect.vue';
import { ChangeStatus } from '@/families/change-status';
import { IntegrationStore } from '@/integrations/store/integration-store';
import { IntegrationExportChildInterface, IntegrationExportChildPostDto } from '@/integrations/models/integration';
import { IntegrationRepository } from '@/integrations/repositories/integration-repository';
import BaseClose from '@/components/base/BaseClose.vue';
import { IntegrationPartners } from '@/integrations/integration-constants';
import DuplicatesReviewModal from '@/families/components/new/potential-duplicates/DuplicatesReviewModal.vue';
import { FromAddFamilyPayload } from '@/families/services/potential-duplicate-service';

const appState = getModule(AppStateStore, store);
const childrenRepo = new ChildrenRepository();
const crmTypesStore = getModule(CrmTypesStore);
const loadingState = getModule(LoadingStore);
const pendingLeadsRepo = new PendingLeadsRepository();
const statusesStore = getModule(StatusesStore);
const featureStore = getModule(FeaturesStore);
const familyMapper = new FamilyMapper();
const familyRepo = new FamiliesRepository();
const centersStore = getModule(CentersStore);
const enrollmentsRepo = new EnrollmentsRepository();
const ecSettingsStore = getModule(EnrollmentCenterSettingsStore);
const fieldsState = getModule(FieldsStore);
const sixAgentState = getModule(SixAgentStore);
const sixAgentUtils = new SixAgentUtils();
const customFieldsStore = getModule(CustomFieldsStore);
const changeStatusUtil = new ChangeStatus();
const integrationsStore = getModule(IntegrationStore);
const integrationRepo = new IntegrationRepository();

@Component({
    components: {
        DuplicatesReviewModal,
        BaseClose,
        StatusChangeSelect,
        ChildScheduleEditor,
        SelectClassPickList,
        PotentialDuplicate,
        DynamicFieldEditor,
        CustomValuesEditor
    }
})
export default class AddFamily extends Mixins(LocaleMixin, BasicValidationMixin) {
    /**
     * Whether to go emit the family navigation or go directly to the family hub.
     */
    @Prop({ default: false }) readonly emitFamilyNavigation!: boolean;
    @Prop({ default: false }) isEnrollmentTeamMode!: boolean;
    /**
     * The pending family to populate the modal with. The pending family is assumed to comm from an incoming call.
     */
    @Prop({ default: null }) pendingFamily!: PendingFamily | null;

    private validForm = false;
    private hasIntegration = false;

    private hasManage = false;
    private managementSystemName = '';
    private eventAddFamily = EventTypes.FAMILY_ADDED;
    private eventClose = EventTypes.CLOSE;
    private eventRejectFamily = EventTypes.FAMILY_REJECTED;
    private updatedEvent = EventTypes.UPDATED;
    private exportChildCheckboxEvent = EventTypes.CHILD_EXPORT_CHECKED;
    private classroomField = ChildFields.CLASS;
    private commentsField = ChildFields.NOTES;
    private scheduleField = ChildFields.SCHEDULE_INFORMATION;
    private expectedStartDateField = ChildFields.EXPECTED_START_DATE;
    private familyModel: FamilyUpdateDtoInterface | FamilyCreateDto = new FamilyCreateDto();
    private inquiryTypes: Array<CrmTypeOption> = [];
    private sourceTypes: Array<CrmTypeOption> = [];
    private phoneTypes: Array<CrmTypeOption> = [];
    private statuses: Array<Status> = [];
    private childStatuses: Array<Status> = [];
    private relationships: Array<Relationship> = [];
    private centers: Array<Center> = [];
    private dupeOpen = false;
    private possibleDupes: Array<Family | PendingFamily | null> = [];
    private familyAdded = false;
    private newFamilyId = 0;
    private showAddress = false;
    private selectedPhoneType: CrmTypeOption | null = null;
    private childExpectedStartDate: Array<string | null | undefined> = [];
    private classroom: Array<number | null> = [];
    private statusUpdates: Array<StatusChangeInterface | null> = [];
    private schedules: Array<EnrollmentSchedule | undefined> = [];
    private fields: Array<Field> = [];
    private exportChildren: Array<IntegrationExportChildInterface | null> = [];
    private key = 0;
    private nonRequiredEmail = [
        (v: string | undefined) => (
            typeof v === 'undefined' ||
            v === '' || (/.+@.+\..*/.test(v) || v.length === 0)) || 'Please enter valid email address'
    ];

    private fromAddFamilyPayload: FromAddFamilyPayload | null = null;

    @Ref('form') readonly form!: VForm;

    // v-model stuff
    @Prop({ default: false }) readonly value!: boolean;

    get modelValue(): boolean {
        return this.value;
    }

    set modelValue(showIt: boolean) {
        // Emit, don't set the value. If you set it, you will get a direct property mutation error.
        this.$emit('input', showIt);
    }

    get hasCrmPlus(): boolean {
        return featureStore.isFeatureEnabled(FeatureConstants.CRM_PLUS_MODE);
    }

    get userCenter(): number | undefined {
        return appState.storedCurrentCenter?.id;
    }

    get locationId(): number {
        if (this.familyModel.primary_guardian.center_id) {
            return this.familyModel.primary_guardian.center_id;
        } else if (this.userCenter) {
            return this.userCenter;
        } else {
            return 0;
        }
    }

    private get isClassroomsFeatureEnabled(): boolean {
        return featureStore.isFeatureEnabled(FeatureConstants.CLASSROOMS);
    }

    private isChildRequiredField(childField: ChildFields) {
        if (childField === ChildFields.SCHEDULE_INFORMATION) {
            return fieldsState.storedChildFields.filter(field => Object.values(ChildScheduleFields).includes(field.value as ChildScheduleFields) && (field.is_client_required || field.is_system_required)).length > 0;
        }
        return fieldsState.storedChildFields.filter(field => field.is_client_required).find(field => field.value === childField);
    }

    get centerET(): number | undefined {
        return appState.storedCurrentCenterEt?.id;
    }

    get isOnEnrollmentTeamDashboard(): boolean {
        return appState.storedCurrentCenterEt !== null;
    }

    get customGuardianFields(): Array<CustomField> {
        return customFieldsStore.storedGuardianFields.filter(field => field.is_client_required);
    }

    get customChildFields(): Array<CustomField> {
        return customFieldsStore.storedChildFields.filter(field => field.is_client_required);
    }

    get guardianFields(): Array<Field> {
        const usedFields = [
            GuardianFields.FIRST_NAME,
            GuardianFields.LAST_NAME,
            GuardianFields.INQUIRY_TYPE,
            GuardianFields.SOURCE,
            GuardianFields.STATUS,
            GuardianFields.EMAIL,
            GuardianFields.PHONE,
            GuardianFields.PHONE_TYPE,
            GuardianFields.RELATION_TO_CHILD,
            GuardianFields.ZIP,
            GuardianFields.ADDRESS,
            GuardianFields.STATE,
            GuardianFields.CITY
        ];
        const status = this.pendingFamily?.status ? this.pendingFamily.status.id : BaseStatuses.NEW_LEAD;
        const ecSettings = ecSettingsStore.storedSettings!;
        return generateGuardianFieldsList(usedFields, true, status, ecSettings, true);
    }

    get childFields(): Array<Field> {
        const usedFields = [
            ChildFields.FIRST_NAME,
            ChildFields.LAST_NAME,
            ChildFields.BIRTHDATE,
            ChildFields.CHILD_STATUS,
            ChildFields.EXPECTED_START_DATE,
            ChildFields.ESTIMATED_REVENUE,
            // these will be handled differently if they are required
            ChildFields.CLASS,
            ChildFields.COMMENTS,
            ChildFields.NOTES
        ];
        const status = this.pendingFamily?.status ? this.pendingFamily.status.id : BaseStatuses.NEW_LEAD;
        return generateChildFieldsList(usedFields, true, status);
    }

    private getFieldModel(field: Field): any {
        if ([GuardianFields.INQUIRY_TYPE as string, GuardianFields.SOURCE as string, GuardianFields.COMMENTS].includes(field.value)) {
            return this.familyModel;
        }
        return this.familyModel?.primary_guardian;
    }

    private updateFamilyModel(payload: CustomValuesUpdateDto) {
        if (this.familyModel.custom_values) {
            const match = this.familyModel.custom_values.find(value => value.custom_value_group === payload.custom_value_group);
            if (match) {
                const index = this.familyModel.custom_values.indexOf(match);
                this.familyModel.custom_values[index] = payload;
            } else {
                this.familyModel.custom_values.push(payload);
            }
        }

    }

    private updateChildModel(payload: CustomValuesUpdateDto, index: number) {
        if (this.familyModel.children[index].custom_values) {
            const match = this.familyModel.children[index].custom_values!.find(value => value.custom_value_group === payload.custom_value_group);
            if (match) {
                const matchIndex = this.familyModel.children[index].custom_values!.indexOf(match);
                this.familyModel.children[index].custom_values![matchIndex] = payload;
            } else {
                this.familyModel.children[index].custom_values!.push(payload);
            }
        }
    }

    private updateStartDate(index: number) {
        if (this.statusUpdates[index]?.expected_start_date) {
            this.childExpectedStartDate[index] = this.statusUpdates[index]?.expected_start_date;
        }
    }

    private isRequiredField(fieldName: string): boolean | undefined {
        switch (fieldName) {
            case GuardianFields.PHONE_TYPE:
                if (this.familyModel.primary_guardian.primary_phone!.number_e164 && this.familyModel.primary_guardian.primary_phone!.number_e164.length > 0) {
                    return true;
                }

                break;
        }
        if (fieldName === this.expectedStartDateField) {
            return fieldsState.storedChildFields.find(field => field.value === fieldName)?.is_client_required;
        }

        return this.fields.find(field => field.value === fieldName)?.is_client_required;
    }

    @Watch('isOnEnrollmentTeamDashboard')
    updateLocationPickList() {
        if (this.isOnEnrollmentTeamDashboard) {
            if (this.centerET) {
                this.familyModel.primary_guardian.center_id = this.centerET;
            }
        }
    }

    @Watch('locationId')
    async updateIntegrations() {
        await this.checkIntegrations();
    }

    @Watch('modelValue')
    async openChanged(newVal: boolean) {
        // reset stuff
        if (newVal) {
            this.form.reset();
            const statusPromise = statusesStore.init();
            const centersPromise = centersStore.initAccessibleCenters();
            await statusPromise;
            await centersPromise;
            this.centers = centersStore.storedAccessibleCenters;
            this.statusUpdates = [];
            this.schedules = [];
            this.classroom = [];
            this.childExpectedStartDate = [];
            this.exportChildren = [];

            // get the default phone type if existed
            const defaultPhoneType = getDefaultPhoneType(this.phoneTypes);
            if (this.pendingFamily) {
                // This is made under the assumption that the pending family is from an incoming phone call.
                this.familyModel = familyMapper.toUpdateDto(this.pendingFamily);
                if (defaultPhoneType && !this.familyModel.primary_guardian.primary_phone!.type) {
                    this.selectedPhoneType = Object.assign({}, defaultPhoneType);
                }

                // If a pending lead is from a text, set default phone type to "CELL"
                const cellPhoneType = this.phoneTypes.find(type => type.identifier === PhoneTypeIdentifiers.CELL);
                if (this.pendingFamily.texts.length > 0 && cellPhoneType) {
                    this.selectedPhoneType = cellPhoneType;
                }
            } else {
                this.familyModel = new FamilyCreateDto();
                this.childExpectedStartDate = [];
                // default to new family
                if (defaultPhoneType) {
                    this.selectedPhoneType = Object.assign({}, defaultPhoneType);
                }
                this.familyModel.primary_guardian.child_relation = getDefaultRelationship(this.relationships);
                this.familyModel.inquiry_type = getDefaultInquiryType(this.inquiryTypes);
                this.familyModel.source_type = getDefaultSource(this.sourceTypes);
                if (sixAgentState.prefillInfo) {
                    this.familyModel.primary_guardian.primary_phone.number_e164 = sixAgentState.prefillInfo.phoneNumber ?? '';
                    this.familyModel.primary_guardian.email = sixAgentState.prefillInfo?.email ?? '';
                }
            }
            this.familyModel.primary_guardian.status = 1;

            this.showAddress = false;
            this.updateLocationPickList();
            this.statuses = statusesStore.statuses.filter((status: Status) => {
                if (!status.id) {
                    return;
                }
                return [
                    BaseStatuses.NEW_LEAD,
                    BaseStatuses.RESPONSIVE,
                    BaseStatuses.TOUR_SCHEDULED,
                    BaseStatuses.TOUR_COMPLETED
                ].includes(status.id);
            });
            this.childStatuses = statusesStore.statuses.filter(status => !status.is_archive || status.id === BaseStatuses.ENROLLED);
            this.key++;
        }
    }

    async created() {
        loadingState.loadingIncrement('addFamilyLoading');
        await featureStore.init();
        const promises = [];
        const familySourcePromise = crmTypesStore.initList(CrmTypeList.FAMILY_SOURCE);
        const familyInquiryPromise = crmTypesStore.initList(CrmTypeList.FAMILY_INQUIRY);
        const phoneTypePromise = crmTypesStore.initList(CrmTypeList.PHONE_TYPE);
        const relationshipPromise = crmTypesStore.initList(CrmTypeList.RELATIONSHIP);
        const fieldsPromise = fieldsState.init();
        promises.push(familyInquiryPromise, familySourcePromise, phoneTypePromise, relationshipPromise, fieldsPromise);
        if (this.hasCrmPlus) {
            const customPromise = customFieldsStore.init();
            promises.push(customPromise);
        }
        if (featureStore.isFeatureEnabled(FeatureConstants.ENROLLMENT_CENTER)) {
            const settingsPromise = ecSettingsStore.init();
            promises.push(settingsPromise);
        }
        await Promise.all(promises);

        this.inquiryTypes = crmTypesStore.listVisibleOptions(CrmTypeList.FAMILY_INQUIRY);
        this.sourceTypes = crmTypesStore.listVisibleOptions(CrmTypeList.FAMILY_SOURCE);
        this.phoneTypes = crmTypesStore.listVisibleOptions(CrmTypeList.PHONE_TYPE);
        this.relationships = crmTypesStore.listVisibleOptions(CrmTypeList.RELATIONSHIP);
        this.fields = sortFields(FieldEntityType.GUARDIAN, fieldsState.storedGuardianFields);

        loadingState.loadingStop('addFamilyLoading');
    }

    private addChild() {
        // set guardian to new lead if adding children
        if (this.familyModel.primary_guardian.status !== BaseStatuses.NEW_LEAD) {
            this.familyModel.primary_guardian.status = BaseStatuses.NEW_LEAD;
        }
        this.familyModel.children.push(new ChildPostDTO());
        this.childExpectedStartDate.push(null);
        this.classroom.push(null);
        this.schedules.push(undefined);
        this.statusUpdates.push(null);
    }

    private removeChild(index: number) {
        this.familyModel.children.splice(index, 1);
        this.childExpectedStartDate.splice(index, 1);
        this.classroom.splice(index, 1);
        this.schedules.splice(index, 1);
        this.statusUpdates.splice(index, 1);
        this.exportChildren.splice(index, 1);
    }

    private handleClose() {
        if (this.familyModel.primary_guardian.first_name ||
            this.familyModel.primary_guardian.last_name ||
            this.familyModel.primary_guardian.email ||
            this.familyModel.primary_guardian.primary_phone!.number_e164
        ) {
            this.$swal({
                text: 'You have entered family information. Are you sure you want to discard this information?',
                icon: 'warning',
                showCancelButton: true,
                showConfirmButton: true,
                confirmButtonText: 'Discard',
                cancelButtonText: 'Go Back'
            }).then((result) => {
                if (result.isConfirmed) {
                    this.modelValue = false;
                }
            });
        } else {
            this.modelValue = false;
        }
    }

    private handleFamilyNav(): void {
        if (this.emitFamilyNavigation) {
            this.$emit(EventTypes.FAMILY_VIEW, this.newFamilyId.toString());
            return;
        }
        this.$router.push({ name: 'family-hub', params: { id: this.newFamilyId.toString() } });
    }

    private async submit() {
        for (const child of this.familyModel.children) {
            child.status = this.familyModel.primary_guardian.status;
            if (isAfterNow(child.date_of_birth)) {
                child.is_estimated_date_of_birth = true;
            }
        }

        if (this.userCenter) {
            if (!this.isOnEnrollmentTeamDashboard) {
                this.familyModel.primary_guardian.center_id = this.userCenter;
            }
        }
        if (this.selectedPhoneType && this.selectedPhoneType.value) {
            this.familyModel.primary_guardian.primary_phone!.type = this.selectedPhoneType!.value;
        }

        loadingState.loadingIncrement('addFamilyLoading');
        const dto = cloneDeep(this.familyModel);
        dto.id = this.pendingFamily ? this.pendingFamily.id : -1;
        this.fromAddFamilyPayload = {
            familyDto: dto,
            pendingFamily: this.pendingFamily ?? null,
            childrenStatusUpdates: this.statusUpdates,
            classrooms: this.classroom,
            schedules: this.schedules
        };
        this.possibleDupes = await checkDuplicatesForNewFamily(this.fromAddFamilyPayload.familyDto);
        this.dupeOpen = this.possibleDupes.length > 1;

        loadingState.loadingDecrement('addFamilyLoading');

        // Meaning no dupes
        if (this.possibleDupes.length === 1) {
            await this.doSubmit();
        }
    }

    private async doSubmit(acceptFamilyEventPayload?: AcceptFamilyEventPayload) {
        loadingState.loadingIncrement('addFamilyLoading');
        const familiesToLink = acceptFamilyEventPayload?.familiesToLink;

        try {
            let children: Array<Child> = [];
            const archivedChildren: Array<ChildCreateDtoInterface | null> = [];
            let centerId: number | null = null;
            let newFamily: Family | null = null;
            if (!this.pendingFamily) {
                // set status changes for new kiddos before hitting the POST endpoint
                this.familyModel.children.forEach((child, index) => {
                    changeStatusUtil.updateChildStatusForPost(this.statusUpdates[index], child);
                    if (child.status === BaseStatuses.ENROLLED) {
                        archivedChildren[index] = child;
                    }
                });
                // remove kiddos if they are starting in enrolled status because we need to hit a different endpoint
                this.familyModel.children = this.familyModel.children.filter(child => child.status !== BaseStatuses.ENROLLED);
                newFamily = await familyRepo.create(this.familyModel as FamilyCreateDto);
                this.newFamilyId = newFamily.id;
                if (archivedChildren.length) {
                    let createdChild: Child | null = null;
                    for (const child of archivedChildren) {
                        if (!child) {
                            continue;
                        }
                        child.enrollments = [];
                        createdChild = (await childrenRepo.createArchived(this.newFamilyId, child as ChildCreateDtoInterface))[0];
                        // add the enrolled children back to the family in the same place they originated so that enrollments updates work correctly later on
                        const index = archivedChildren.indexOf(child);
                        newFamily.children.splice(index, 0, createdChild);
                    }
                }
                if (sixAgentState.loggedIn) {
                    sixAgentUtils.addPossibleContact(newFamily);
                    sixAgentState.deletePrefill({
                        contactId: '',
                        email: this.familyModel.primary_guardian.email ?? null,
                        phoneNumber: this.familyModel.primary_guardian.primary_phone?.number_e164 ?? null
                    });
                }
                children = newFamily.children;
                centerId = newFamily.primary_guardian.center_id;
            } else {
                const statusId = this.familyModel.primary_guardian.status ?? 1;
                delete this.familyModel.primary_guardian.status;
                const childrenModels = cloneDeep(this.familyModel.children);
                this.familyModel.children = [];
                await familyRepo.update(this.familyModel as FamilyUpdateDtoInterface);
                await pendingLeadsRepo.acceptFamily(this.pendingFamily.id as number);
                if (statusId !== BaseStatuses.NEW_LEAD) {
                    // Update the family again with the status included
                    this.familyModel.primary_guardian.status = statusId;
                    await familyRepo.update(this.familyModel as FamilyUpdateDtoInterface);
                }
                this.newFamilyId = this.pendingFamily.id;
                centerId = this.familyModel.primary_guardian.center_id;

                let createdChild: Child | null = null;
                childrenModels.forEach((child, index) => {
                    if (this.statusUpdates[index]) {
                        changeStatusUtil.updateChildStatusForPost(this.statusUpdates[index], child);
                    } else {
                        child.status = statusId;
                    }
                });
                for (const child of childrenModels) {
                    if (child.status === BaseStatuses.ENROLLED) {
                        createdChild = (await childrenRepo.createArchived(this.pendingFamily.id, child as ChildCreateDtoInterface))[0];
                    } else {
                        createdChild = (await childrenRepo.create(this.pendingFamily.id, child as ChildCreateDtoInterface))[0];
                    }
                    children.push(createdChild);
                }
            }

            for (let i = 0; i < children.length; i++) {
                if (centerId) {
                    const updateObject: EnrollmentUpdateDtoInterface = {
                        id: children[i].enrollments[0].id,
                        center_id: centerId,
                        child_id: children[i].id
                    };
                    if (this.childExpectedStartDate[i]) {
                        updateObject.expected_start_date = this.childExpectedStartDate[i];
                    }
                    if (this.isClassroomsFeatureEnabled && this.classroom[i]) {
                        updateObject.classroom_id = this.classroom[i];
                    }
                    if (this.schedules[i]) {
                        updateObject.schedule = this.schedules[i];
                    }
                    if (children[i].status?.id === BaseStatuses.WAIT_LIST) {
                        const waitlist: WaitlistDetailsUpdate = {
                            reason: children[i].status_details?.wait_list_reason?.id ?? null,
                            date: children[i].status_details?.wait_list_date ?? '',
                            comments: children[i].status_details?.wait_list_comment ?? '',
                            fee: children[i].status_details?.wait_list_fee ?? '',
                            fee_paid_datetime: children[i].status_details?.wait_list_fee_paid_date ?? null,
                            is_fee_paid: !!children[i].status_details?.wait_list_fee_paid_date,
                            type: children[i].status_details?.wait_list_type?.id ?? null,
                            priority: children[i].status_details?.wait_list_priority?.id ?? null
                        };
                        updateObject.wait_list = waitlist;
                    }
                    if (children[i].status?.id === BaseStatuses.ENROLLED) {
                        const enrollment: EnrollmentDateReasonWrite = {
                            reason: children[i].status_details?.enrolled_reason?.id ?? null,
                            date: children[i].status_details?.enrolled_date ?? '',
                            comments: children[i].status_details?.enrolled_comment ?? ''
                        };
                        updateObject.enrollment = enrollment;
                    }
                    await enrollmentsRepo.update(updateObject);
                }
                if (this.hasIntegration && this.exportChildren[i]) {
                    await this.exportChildEvent(children[i].id, i);
                }
            }
            if (familiesToLink) {
                // keys are always strings in JS, but I actually just want numbers
                for (const id of Object.keys(familiesToLink).map(Number)) {
                    if (!familiesToLink[id]) {
                        continue;
                    }
                    await familyRepo.linkFamily(this.newFamilyId, { family_id: id });
                }
            }
            if (!this.pendingFamily && newFamily) {
                await familyRepo.getOne(newFamily.id, { log_view: true }, true);
                appState.incrementViewed();
                this.familyAdded = true;
                this.$emit('familyCreated', newFamily);
            } else if (this.pendingFamily) {
                this.$emit(EventTypes.ACCEPTED);
            }
        } catch (error) {
            await this.$swal({
                text: 'Something went wrong. Please try again later.',
                icon: 'error'
            });
        }

        loadingState.loadingDecrement('addFamilyLoading');
        this.modelValue = false;
    }

    private async addFamilySideEffects(addedFamilyId: number | null) {
        this.dupeOpen = false;
        loadingState.loadingIncrement('addFamilyLoading');
        if (addedFamilyId) {
             if (!this.pendingFamily) {
                const newFamily = await familyRepo.getOne(addedFamilyId, { log_view: true }, true);
                if (sixAgentState.loggedIn) {
                    sixAgentUtils.addPossibleContact(newFamily);
                    sixAgentState.deletePrefill({
                        contactId: '',
                        email: this.familyModel.primary_guardian.email ?? null,
                        phoneNumber: this.familyModel.primary_guardian.primary_phone?.number_e164 ?? null
                    });
                }
                appState.incrementViewed();
                this.newFamilyId = addedFamilyId;
                this.familyAdded = true;
                this.$emit('familyCreated', newFamily);
            }
        } else {
            this.$emit(EventTypes.FAMILY_ACCEPTED);
        }
        this.modelValue = false;
        loadingState.loadingDecrement('addFamilyLoading');
    }

    private cancelDupe() {
        this.dupeOpen = false;
    }

    async checkIntegrations() {
        // Check if they have the CMS integrations add-on feature enabled
        // If not, don't allow export
        const features = featureStore.entities;
        for (const feature of features) {
            if (feature.identifier === FeatureConstants.CMS_ADD_ON_ID && !feature.is_active) {
                this.hasIntegration = false;
                return;
            }
        }

        await integrationsStore.init();
        if (this.locationId) {
            const centerIntegration = await integrationsStore.getByCenterId(this.locationId);

            if (centerIntegration && centerIntegration.is_active) {
                this.hasIntegration = true;
                this.managementSystemName = centerIntegration.name;
                this.hasManage = centerIntegration.name === IntegrationPartners.MANAGE;
            }
        }
    }

    private async exportChildEvent(childId: number, index: number) {
        if (this.newFamilyId) {
            const child = await childrenRepo.retrieve(this.newFamilyId, childId);
            if (this.hasIntegration && isChildExportable(child) && this.exportChildren[index]) {
                if (this.exportChildren[index]?.export) {
                    const exportChildDto = new IntegrationExportChildPostDto();
                    exportChildDto.child_id = childId;
                    await integrationRepo.exportChild(exportChildDto);
                }
            }
        }
    }

    /**
     * Retrieve IntegrationExportChildInterface from StatusChangeSelect.
     *
     * @param child
     * @private
     */
    private updateExport(child: IntegrationExportChildInterface, index: number) {
        this.exportChildren[index] = child;
    }

}
