





















































































import { Component, Mixins, Ref, Watch } from 'vue-property-decorator';
import { LocaleMixin } from '@/locales/locale-mixin';
import { LOADING_GLOBAL_KEY } from '@/constants/loading-constants';
import { getModule } from 'vuex-module-decorators';
import { LoadingStore } from '@/store/loading-store';
import { AuthStore } from '@/store/auth-store';
import store from '@/store';
import { StaffRepository } from '@/staff/repositories/staff-repository';
import { VForm } from '@/types/types';
import { BasicValidationMixin } from '@/validation/basic-validation-mixin';
import { PwStrength } from '@/staff/pw-strength';
import { User, UserCreateDto, UserUpdateDto } from '@/staff/models/user';
import { UserMapper } from '@/staff/mappers/user-mapper';
import PasswordStrengthLabel from '@/staff/components/PasswordStrengthLabel.vue';

const loadingState = getModule(LoadingStore);
const authState = getModule(AuthStore, store);
const staffRepository = new StaffRepository();
const userMapper = new UserMapper();

@Component({
    components: { PasswordStrengthLabel }
})
export default class ResetPassword extends Mixins(LocaleMixin, BasicValidationMixin) {
    private resetCode = '';
    private password = '';
    private passwordConfirm = '';
    private validForm = false;
    private passwordStrength = 0;
    private userDto: UserCreateDto | UserUpdateDto = userMapper.toCreateDto({} as User);

    get passwordRules() {
        return [
            ...this.passwordField,
            this.passwordStrength >= PwStrength.MIN_STRENGTH || PwStrength.PW_ERROR
        ];
    }

    private get passwordMatchRule() {
        return [
            this.password === this.passwordConfirm || 'Passwords must match'
        ];
    }

    async created() {
        loadingState.loadingIncrement(LOADING_GLOBAL_KEY);
        await PwStrength.init();
        const userInfo = await staffRepository.getOne(authState.id!);
        this.userDto = userMapper.toUpdateDto(userInfo);
        loadingState.loadingDecrement(LOADING_GLOBAL_KEY);
    }

    // definitions for typescript
    @Ref('resetPasswordForm') readonly resetPasswordForm!: VForm;

    @Watch('password')
    private async onPasswordChange() {
        this.userDto.password = this.password;
        this.passwordStrength = await PwStrength.getStrength(this.userDto);
    }

    public async sendCode() {
        loadingState.loadingIncrement(LOADING_GLOBAL_KEY);
        try {
            await staffRepository.sendPasswordResetCode();
            this.$swal({ text: 'Email sent', icon: 'success' });
        } catch (error) {
            this.$swal({ text: 'Something went wrong', icon: 'error' });
        }
        loadingState.loadingStop(LOADING_GLOBAL_KEY);
    }

    public async resetPassword() {
        if (!this.validForm) {
            this.resetPasswordForm.validate();
            return;
        }
        loadingState.loadingIncrement(LOADING_GLOBAL_KEY);
        try {
            await staffRepository.getOne(authState.id!);
            await staffRepository.resetPassword(this.password, this.resetCode);
            const userInfo = await staffRepository.getOne(authState.id!);
            authState.storeUserInfo(userInfo);
            // we'll use the created hook in LoginMain to redirect them properly
            this.$router.push('/');
            loadingState.loadingStop(LOADING_GLOBAL_KEY);
        } catch (error) {
            loadingState.loadingStop(LOADING_GLOBAL_KEY);
            this.$swal({ text: error?.response?.data?.detail || 'Something went wrong', icon: 'error' });
        }
    }
}
