




































































































































































































































































































































































































































































































































































































































































import { Component, Mixins, Prop, Watch } from 'vue-property-decorator';

import CrmTypeSelectList from '@/crm-types/components/CrmTypeSelectList.vue';
import StatusChangeSelect from '@/families/components/StatusChangeSelect.vue';
import InlineEditable from '@/components/base/InlineEditable.vue';
import { EventTypes } from '@/constants/event-type-constants';
import { getModule } from 'vuex-module-decorators';
import { AppStateStore } from '@/store/app-state-store';
import { LoadingStore } from '@/store/loading-store';
import PotentialDuplicateService, {
    ChildEntry,
    FamilyEntry, FromAddFamilyPayload,
    MergeLayer,
    PotentialDuplicateActionOption
} from '@/families/services/potential-duplicate-service';
import {
    AcceptFamilyEventPayload,
    Family,
    PendingFamily,
    PotentialDuplicateActionConstants
} from '@/families/models/family';
import { LocaleMixin } from '@/locales/locale-mixin';
import DuplicatesDifferenceIndicator
    from '@/families/components/new/potential-duplicates/DuplicatesDifferenceIndicator.vue';
import { CrmTypeOption } from '@/crm-types/models/crm-type';
import DuplicateStatusChangeSelect
    from '@/families/components/new/potential-duplicates/DuplicateStatusChangeSelect.vue';
import {
    buildChildrenOverwritesFromDiffs,
    buildFamilyOverwritesFromDiffs,
    filterNonHideActions, generateTemporaryLeadIdString,
    getActionOptionsForFamily,
    getDialogSize,
    mergeChildInfo,
    mergeGuardianInfo
} from '@/families/potential-duplicate-utils';
import cloneDeep from 'lodash/cloneDeep';
import { StatusChangeInterface } from '@/families/models/status';
import { getFieldValueByPath, setFieldValueByPath } from '@/utils/object-path-utils';

const appState = getModule(AppStateStore);
const loadingState = getModule(LoadingStore);
const potentialDuplicateService = new PotentialDuplicateService();

@Component({
  components: { DuplicateStatusChangeSelect, InlineEditable, StatusChangeSelect, CrmTypeSelectList, DuplicatesDifferenceIndicator }
})

export default class DuplicatesReviewModal extends Mixins(LocaleMixin) {
    @Prop({ default: false }) readonly value!: boolean;
    @Prop({ type: Array, default: null, required: true }) readonly duplicates!: Array<Family | PendingFamily | null>;
    @Prop({ type: Boolean, default: false }) readonly isPending!: boolean;
    @Prop({ type: Object, default: null }) readonly fromAddFamilyPayload!: FromAddFamilyPayload | null;

    private loadingKey = 'duplicatesReviewModal';
    private isLoaded = false;
    private localDialogSize = 'potential-duplicate-dialog-medium';
    private originalFamilyEntries: Array<FamilyEntry> = [];
    private currentFamilyEntries: Array<FamilyEntry> = [];
    private originalChildrenGroupedByName: Record<string, Array<ChildEntry>> = {};
    private currentChildrenGroupedByName: Record<string, Array<ChildEntry>> = {};
    private familyDifferencesRecord: Record<string, boolean> = {};
    private childrenDifferencesArray: Array<{ name: string; differences: Record<string, boolean> }> = [];
    private availableInquiryTypes: Array<CrmTypeOption> = [];
    private availableSourceTypes: Array<CrmTypeOption> = [];
    private availableStatusIdsForChildlessFamilies: Array<number> = [];
    private centerNames: Array<string> = [];
    private currentActionOptions: Record<number, PotentialDuplicateActionOption | null> = {};
    private previousActionOptions: Record<number, PotentialDuplicateActionOption | null> = {};
    private mergeTracking: Map<number, Array<number>> = new Map();
    private mergeHistory: Map<number, Array<MergeLayer>> = new Map();
    private updatedEvent = EventTypes.UPDATED;
    private tempIdString = '';

    get modelValue(): boolean {
        return this.value;
    }

    set modelValue(showIt: boolean) {
        this.$emit('input', showIt);
    }

    get familyEntries(): Array<FamilyEntry> {
        return this.currentFamilyEntries;
    }

    set familyEntries(value: Array<FamilyEntry>) {
        this.currentFamilyEntries = value;
        potentialDuplicateService.updateFamilyEntries(value);
    }

    get childrenGroupedByName(): Record<string, Array<ChildEntry>> {
        return this.currentChildrenGroupedByName;
    }

    set childrenGroupedByName(value: Record<string, Array<ChildEntry>>) {
        this.currentChildrenGroupedByName = value;
        potentialDuplicateService.updateChildrenGroupedByName(value);
    }

    get isSaveDisabled() {
        // Filter out records with action type `HIDE`
        const validActions = Object.values(this.currentActionOptions).filter((action) => {
            if (!action) return true; // Skip null values
            const actionType = action.value.split('_')[0];
            return actionType !== PotentialDuplicateActionConstants.HIDE;
        });
        // If any valid action is null, saving should be disabled
        return validActions.some((action) => action === null);
    }

    get isMini() {
        return appState.isMini;
    }

    @Watch('modelValue')
    private async loadFamiliesData() {
        if (this.modelValue) {
            loadingState.loadingIncrement(this.loadingKey);
            this.isLoaded = false;
            await this.initializeData();
            await this.reloadData();

            // Track the actions of save, merge, link, hide, and reject in this component, too much hassle to track in service class
            this.cleanMergeTracking();
            this.cleanMergeHistory();

            this.initializeActions();
            this.isLoaded = true;
            loadingState.loadingDecrement(this.loadingKey);
        }
    }

    @Watch('familyEntries', { deep: true })
    private onFamiliesDtoChange(newValue: Array<FamilyEntry>) {
        potentialDuplicateService.updateFamilyEntries(newValue);
    }

    @Watch('childrenGroupedByName', { deep: true })
    private onChildrenGroupedByNameChange(newValue: Record<string, Array<ChildEntry>>) {
        potentialDuplicateService.updateChildrenGroupedByName(newValue);
    }

    private updateStatusSelect(index: number, status: StatusChangeInterface | null) {
        this.familyEntries[index].statusUpdates = status;
    }

    private updateChildStatusSelect(name: string, index: number, status: StatusChangeInterface | null) {
        this.childrenGroupedByName[name][index].statusUpdates = status;
    }

    private async initializeData() {
        this.currentFamilyEntries = [];
        this.originalFamilyEntries = [];
        this.currentChildrenGroupedByName = {};
        this.originalChildrenGroupedByName = {};
        this.centerNames = [];
        this.familyDifferencesRecord = {};
        this.childrenDifferencesArray = [];
        this.tempIdString = generateTemporaryLeadIdString();
        await potentialDuplicateService.init(this.duplicates, this.fromAddFamilyPayload);
        this.currentFamilyEntries = potentialDuplicateService.familyEntries;
        this.originalFamilyEntries = cloneDeep(this.currentFamilyEntries);
        this.currentChildrenGroupedByName = potentialDuplicateService.childrenGrouped;
        this.originalChildrenGroupedByName = cloneDeep(this.currentChildrenGroupedByName);
        this.centerNames = potentialDuplicateService.centerRows;
        this.familyDifferencesRecord = potentialDuplicateService.familyDifferences;
        this.childrenDifferencesArray = potentialDuplicateService.childrenDifferences;
    }

    private async reloadData() {
        await potentialDuplicateService.setupSelectListOptions();
        this.localDialogSize = getDialogSize(this.familyEntries.length);
        this.availableInquiryTypes = potentialDuplicateService.inquiryTypesOptions;
        this.availableSourceTypes = potentialDuplicateService.sourceTypesOptions;
        this.availableStatusIdsForChildlessFamilies = potentialDuplicateService.statusesForChildlessFamilies;
    }

    private initializeActions() {
        this.currentActionOptions = {};
        for (const familyEntry of this.familyEntries) {
            this.updateSelectedActionOption(familyEntry.familyDto.id!, null);
            this.previousActionOptions[familyEntry.familyDto.id!] = null;
        }
    }

    private cleanMergeHistory(): void {
      this.mergeHistory = new Map();
    }

    private cleanMergeTracking() {
        this.mergeTracking.clear();
        this.familyEntries.forEach((familyEntry) => {
            this.mergeTracking.set(familyEntry.familyDto.id!, []);
        });
    }

    private closeDialog() {
        this.modelValue = false;
        this.$emit(EventTypes.CLOSE);
    }

    private retrieveActionOptions(familyId: number): Array<PotentialDuplicateActionOption> {
        return getActionOptionsForFamily(familyId, this.familyEntries, this.currentActionOptions, this.tempIdString);
    }

    private async onActionChange(familyId: number, action: PotentialDuplicateActionOption) {
        const actionType = action.value.split('_')[0];
        const previousAction = this.previousActionOptions[familyId];
        const previousActionType = previousAction ? previousAction.value.split('_')[0] : null;

        if (actionType === PotentialDuplicateActionConstants.HIDE) {
            await this.handleHideAction(familyId, action);
            return;
        }

        if (actionType !== PotentialDuplicateActionConstants.SAVE) {
            this.resetReferencesToFamily(familyId);
        }

        if (actionType === PotentialDuplicateActionConstants.MERGE) {
            // We parse out the target from e.g. "merge-4"
            const targetFamilyId = parseInt(action.value.split('_')[1], 10);
            if (previousAction && previousActionType && previousActionType === PotentialDuplicateActionConstants.MERGE) {
                const previousTargetFamilyId = parseInt(previousAction.value.split('_')[1]);
                if (previousTargetFamilyId !== targetFamilyId) {
                    // UNMERGE if previously "merge" to a different target
                    this.handleUnmergeAction(familyId, previousTargetFamilyId);
                }
            }
            this.handleMergeAction(familyId, targetFamilyId);
        } else {
            // UNMERGE if previously "merge"
            if (previousAction && previousActionType && previousActionType === PotentialDuplicateActionConstants.MERGE) {
                const previousTargetFamilyId = parseInt(previousAction.value.split('_')[1], 10);
                this.handleUnmergeAction(familyId, previousTargetFamilyId);
            }
        }

        this.updateActionOptions(familyId, action);
        await this.reloadData();
    }

    private handleMergeAction(sourceFamilyId: number, targetFamilyId: number) {
        if (!this.mergeTracking.has(targetFamilyId)) {
            this.mergeTracking.set(targetFamilyId, []);
        }
        const mergesForTarget = this.mergeTracking.get(targetFamilyId) || [];
        // avoid duplicates
        if (!mergesForTarget.includes(sourceFamilyId)) {
            mergesForTarget.push(sourceFamilyId);
            this.mergeTracking.set(targetFamilyId, mergesForTarget);
            // Call `reapplyAllMergesInOrder` so that merges happen in ascending order
            this.reapplyAllMergesInOrder(targetFamilyId, mergesForTarget);
        }
    }

    private handleUnmergeAction(sourceFamilyId: number, previousTargetFamilyId: number) {
        if (!this.mergeTracking.has(previousTargetFamilyId)) return;
        let mergesForTarget = this.mergeTracking.get(previousTargetFamilyId) || [];
        mergesForTarget = mergesForTarget.filter(id => id !== sourceFamilyId);
        this.mergeTracking.set(previousTargetFamilyId, mergesForTarget);

        // partial unmerge to preserve user edits
        this.partialUnmerge(previousTargetFamilyId, sourceFamilyId);

        // re-apply merges from the remaining sources, in ascending ID
        if (mergesForTarget.length > 0) {
            this.reapplyAllMergesInOrder(previousTargetFamilyId, mergesForTarget);
        } else {
            // no merges remain for this target
            this.mergeHistory.delete(previousTargetFamilyId);
            this.mergeTracking.set(previousTargetFamilyId, []);
        }
    }

    private partialUnmerge(targetId: number, sourceId: number): void {
        const layersForTarget = this.mergeHistory.get(targetId);
        if (!layersForTarget) return;

        const targetIndex = this.familyEntries.findIndex(f => f.familyDto.id! === targetId);
        if (targetIndex === -1) return;
        const targetFamilyEntry = this.familyEntries[targetIndex];

        // Collect all layers that match sourceId
        const layersToRemove = layersForTarget.filter(layer => layer.sourceId === sourceId);
        if (layersToRemove.length === 0) return;

        for (const layer of layersToRemove) {
            // Revert only fields that are still identical to the mergedValue
            for (const overwrite of layer.familyOverwrites) {
                const currentVal = getFieldValueByPath(targetFamilyEntry, overwrite.fieldPath);
                if (currentVal === overwrite.mergedValue) {
                    setFieldValueByPath(targetFamilyEntry, overwrite.fieldPath, overwrite.oldValue);
                }
            }

            for (const [childName, overwrites] of Object.entries(layer.childrenOverwrites)) {
                const targetChildrenEntries = this.childrenGroupedByName[childName];
                if (!targetChildrenEntries) continue; // Ensure the child group exists

                const targetChildEntry = targetChildrenEntries[targetIndex]; // Match family index
                if (!targetChildEntry) continue;

                let updated = false; // Track if modifications happen

                for (const overwrite of overwrites) {
                    const currentVal = getFieldValueByPath(targetChildEntry, overwrite.fieldPath);
                    if (currentVal === overwrite.mergedValue) {
                        setFieldValueByPath(targetChildEntry, overwrite.fieldPath, overwrite.oldValue);
                        updated = true;
                    }
                }

                // Ensure update is reflected in childrenGroupedByName
                if (updated) {
                    this.childrenGroupedByName[childName][targetIndex] = { ...targetChildEntry };
                }
            }
        }

        // Remove all processed layers
        this.mergeHistory.set(
            targetId,
            layersForTarget.filter(layer => layer.sourceId !== sourceId)
        );
    }

    // Returns the column index in `familyEntries` for the given family ID so we know who is left vs. right.
    private getColumnIndexForFamilyId(familyId: number): number {
      return this.familyEntries.findIndex(entry => entry.familyDto.id! === familyId);
    }

    private reapplyAllMergesInOrder(targetId: number, sourceIds: number[]) {
        // Sort sources by column index (left to right)
        sourceIds.sort((a, b) => this.getColumnIndexForFamilyId(a) - this.getColumnIndexForFamilyId(b));

        const processedSources = new Set<number>(); // Prevent duplicate processing

        for (const srcId of sourceIds) {
            if (processedSources.has(srcId)) {
                continue; // Skip redundant processing
            }
            this.doMerge(targetId, srcId);
            processedSources.add(srcId);
        }
    }

    // Handle "Skip/Hide This Record" action
    private async handleHideAction(familyId: number, action: PotentialDuplicateActionOption) {
        const result = await this.$swal({
            text: 'Are you sure you want to remove this column from the screen? Any edits you may have made will not be saved.',
            showConfirmButton: true,
            showCancelButton: true,
            confirmButtonText: 'Hide',
            cancelButtonText: 'Cancel',
            icon: 'warning'
        });

        if (result.isConfirmed) {
            this.hideFamilyFromDuplicates(familyId);
            await this.reloadData();
            this.updateActionOptions(familyId, action);
            this.resetReferencesToFamily(familyId);
        } else {
            await this.reloadData();
            this.revertAction(familyId);
        }
    }

    // Reset references to the specified family when moving away from "Save"
    private resetReferencesToFamily(familyId: number): void {
        this.familyEntries.forEach((familyEntry) => {
            if (this.currentActionOptions[familyEntry.familyDto.id!]?.value.includes(`-${familyId}`) && familyEntry.familyDto.id !== familyId) {
                this.updateSelectedActionOption(familyEntry.familyDto.id!, null); // Reset to default empty state
                this.handleUnmergeAction(familyEntry.familyDto.id!, familyId);
            }
        });
    }

    // Update the selected and current action options
    private updateActionOptions(familyId: number, action: PotentialDuplicateActionOption): void {
        this.updateSelectedActionOption(familyId, action);
        this.previousActionOptions[familyId] = action;
    }

    // Revert the action for a family to the previous state
    private revertAction(familyId: number): void {
        const previousAction = this.previousActionOptions[familyId] || null;
        this.updateSelectedActionOption(familyId, previousAction);
    }

    // Remove a family from the duplicates list and clear references to it
    private hideFamilyFromDuplicates(familyId: number): void {
        const deletedIndex = this.familyEntries.findIndex((entry) => entry.familyDto.id! === familyId);
        if (deletedIndex !== -1) {
            this.familyEntries.splice(deletedIndex, 1);
            this.centerNames.splice(deletedIndex, 1);
            Object.keys(this.childrenGroupedByName).forEach((key) => {
                this.childrenGroupedByName[key].splice(deletedIndex, 1);
            });
            Object.keys(this.childrenGroupedByName).forEach((key) => {
                // Collect unique status IDs from all entries that have non-null statusUpdates
                const uniqueStatusIds = new Set<number>();
                this.childrenGroupedByName[key].forEach((childEntry) => {
                    if (childEntry.statusUpdates) {
                        uniqueStatusIds.add(childEntry.statusUpdates.status);
                    }
                });
                // Convert the set into an array of unique statuses
                const uniqueStatusesArray = Array.from(uniqueStatusIds);
                // Update availableStatuses for each child entry in this key
                this.childrenGroupedByName[key].forEach((childEntry) => {
                    childEntry.availableStatuses = uniqueStatusesArray;
                });
            });
        }
        this.familyEntries.forEach((familyEntry) => {
            if (this.currentActionOptions[familyEntry.familyDto.id!]?.value.includes(`-${familyId}`)) {
                this.updateSelectedActionOption(familyEntry.familyDto.id!, null); // Reset to default empty state
            }
        });
    }

    private updateSelectedActionOption(familyId: number, action: PotentialDuplicateActionOption | null): void {
        this.$set(this.currentActionOptions, familyId, action);
    }

    private doMerge(targetFamilyId: number, sourceFamilyId: number) {
        const targetIndex = this.familyEntries.findIndex(f => f.familyDto.id! === targetFamilyId);
        const sourceIndex = this.familyEntries.findIndex(f => f.familyDto.id! === sourceFamilyId);
        if (targetIndex === -1 || sourceIndex === -1) return;

            const targetFamilyEntry = this.familyEntries[targetIndex];
            const sourceFamilyEntry = this.familyEntries[sourceIndex];

        // snapshot before
        const beforeFamilyEntry = cloneDeep(targetFamilyEntry);
        const beforeChildrenGroupedByName = cloneDeep(this.childrenGroupedByName);

        // call existing "mergeGuardianInfo" + "mergeChildInfo"
        mergeGuardianInfo(targetFamilyEntry, sourceFamilyEntry, this.familyEntries);
        mergeChildInfo(targetIndex, sourceIndex, this.childrenGroupedByName);

        // snapshot after
        // figure out which fields changed from null -> newValue
        const familyOverwrites = buildFamilyOverwritesFromDiffs(beforeFamilyEntry, targetFamilyEntry);
        const childrenOverwrites = buildChildrenOverwritesFromDiffs(targetIndex, beforeChildrenGroupedByName, this.childrenGroupedByName);

         if (familyOverwrites.length === 0) {
            return; // Skip adding an empty merge record
        }

        // store them in mergeHistory
        if (!this.mergeHistory.has(targetFamilyId)) {
            this.mergeHistory.set(targetFamilyId, []);
        }
        this.mergeHistory.get(targetFamilyId)!.push({
            sourceId: sourceFamilyId,
            familyOverwrites,
            childrenOverwrites
        });

    }

    private async save() {
        loadingState.loadingIncrement(this.loadingKey);
        const familyBeingViewId = this.familyEntries[0].familyDto.id!;
        const newFamilyId = await potentialDuplicateService.save(filterNonHideActions(this.currentActionOptions));
        const familyBeingViewAction = this.currentActionOptions[familyBeingViewId];
        if (familyBeingViewAction) {
            const [familyBeingViewActionType, targetFamilyString] = familyBeingViewAction.value.split('_');
            const targetFamilyId = parseInt(targetFamilyString);
            if (familyBeingViewActionType === PotentialDuplicateActionConstants.SAVE || familyBeingViewActionType === PotentialDuplicateActionConstants.LINK) {
                if (this.isPending) {
                    this.$emit(EventTypes.FAMILY_ACCEPTED, { acceptedInDuplicateModal: true } as AcceptFamilyEventPayload);
                } else {
                    if (this.fromAddFamilyPayload) {
                        this.$emit(EventTypes.FAMILY_ADDED, newFamilyId);
                    } else {
                        this.$emit(EventTypes.FAMILY_UPDATED);
                    }
                }
            }

            if (familyBeingViewActionType === PotentialDuplicateActionConstants.MERGE) {
                this.$emit(EventTypes.FAMILY_MERGED, targetFamilyId);
            }

            if (familyBeingViewActionType === PotentialDuplicateActionConstants.REJECT && familyBeingViewId > -1) {
                this.$emit(EventTypes.FAMILY_REJECTED, familyBeingViewId, null, true);
            }
        }

        loadingState.loadingDecrement(this.loadingKey);
        this.closeDialog();
    }

}
