






















import { Component, Prop, Vue } from 'vue-property-decorator';

@Component
export default class DuplicatesDifferenceIndicator extends Vue {
    @Prop({ type: Boolean, default: true }) readonly show!: boolean;
    @Prop({ type: String, default: 'Note that different families have different data in this row. Click to edit data if desired.' }) readonly tooltipText!: string;
    @Prop({ type: Boolean, default: false }) readonly isChildStatus!: boolean; // New prop for child status
    @Prop({ type: Boolean, default: false }) readonly isFamilyStatus!: boolean; // New prop for family status
    @Prop({ type: Boolean, default: false }) readonly isChildStartDate!: boolean; // New prop for child start date
}
