import { OptionsType, zxcvbn, zxcvbnOptions } from '@zxcvbn-ts/core';
import { UserCreateDto, UserUpdateDto } from '@/staff/models/user';

export class PwStrength {
    static loaded = false;
    static MIN_STRENGTH = 3;
    static PW_ERROR = 'Password must be stronger.';

    public static async init() {
        if (this.loaded) {
            return;
        }
        zxcvbnOptions.setOptions(await this.getOptions());
        this.loaded = true;
    }

    private static async getOptions(): Promise<OptionsType> {
        const zxcvbnCommonPackage = await import('@zxcvbn-ts/language-common');
        const zxcvbnEnPackage = await import('@zxcvbn-ts/language-en');

        return {
            dictionary: {
                ...zxcvbnCommonPackage.dictionary,
                ...zxcvbnEnPackage.dictionary
            },
            graphs: zxcvbnCommonPackage.adjacencyGraphs,
            useLevenshteinDistance: true,
            translations: zxcvbnEnPackage.translations
        };
    }

    static async getStrength(userDto: UserCreateDto | UserUpdateDto) {
        const results = zxcvbn(
            userDto.password ?? '',
            [userDto.username, userDto.email_address, userDto.first_name, userDto.last_name]);
        return results.score;
    }

    static getStrengthLabel(score: number): string {
        switch (score) {
            case 0:
            case 1:
                return 'weak';
            case 2:
                return 'fair';
            case 3:
            case 4:
                return 'strong';
            default:
                return '';
        }
    }
}
