import { AbstractRepository, ApiPagination, ApiParameters } from '@/repositories/abstract-repository';
import {
    StaffPosition, StaffPositionUpdateDto,
    StaffSortParameter,
    User,
    UserUpdate,
    UserUpdateDto,
    UserUpdateWrapper
} from '@/staff/models/user';
import { CrmApiResponse } from '@/models/base';

export class StaffRepository extends AbstractRepository<User> {
    readonly endpoint = 'staff';

    public async update(payload: UserUpdate | UserUpdateDto): Promise<void> {
        const wrapper: UserUpdateWrapper = [payload];
        await this.client.put(this.endpoint, wrapper);
    }

    public async getAllStaffThatCanAccessOrgByOrgId(orgId: number): Promise<Array<User>> {
        // We are using a bit of a different endpoint for this.
        const response = await this.client.get<Array<User>>(`organizations/${orgId}/staff`);
        return response.data;
    }

    public async getAllAccessibleStaff(
        pagination: ApiPagination | null = null,
        sortParam: StaffSortParameter | null = null,
        search: string | null = null,
        onlyActive = true
    ): Promise<CrmApiResponse<User>> {
        const params: ApiParameters = {
            only_accessible_staff: true,
            only_active: onlyActive
        };
        Object.assign(params, sortParam);

        if (search) {
            params.search = search;
        }

        return this.get(pagination, params);
    }

    public async getStaffMemberPositions(staffId: number, queryParams: ApiParameters | null = null): Promise<Array<StaffPosition>> {
        const response = await this.client.get<Array<StaffPosition>>(`${this.endpoint}/${staffId}/positions`, { params: queryParams });
        return response.data;
    }

    /**
     * Update the positions for the given staff.
     */
    public async updateStaffMemberPositions(staffId: number, payload: Array<StaffPositionUpdateDto>): Promise<void> {
        await this.client.put(`${this.endpoint}/${staffId}/positions`, payload);
    }

    /**
     * Get the staff positions for every center
     */
    public async getCenterStaffPositions(): Promise<Array<StaffPosition>> {
        const response = await this.client.get(`${this.endpoint}/positions`);
        return response.data;
    }

    public async resendVerificationEmail(staffId: number): Promise<void> {
        await this.client.post(`/resend-verification-email/${staffId}`);
    }

    public async sendPasswordResetCode(): Promise<void> {
        await this.client.post(`${this.endpoint}/send-password-reset-code`);
    }

    public async resetPassword(password: string, code: string): Promise<void> {
        await this.silentClient.put(`${this.endpoint}/password-reset-with-code`, { password, code });
    }
}
