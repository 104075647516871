/**
 * Common helper methods for reading/writing nested fields by dot-notation.
 */

/**
 * Reads a nested property using a dot-notation path.
 * e.g. getFieldValueByPath(someObj, "primary_guardian.address.locality")
 */
export function getFieldValueByPath(obj: any, path: string): any {
    return path.split('.').reduce((acc, key) => (acc ? acc[key] : undefined), obj);
}

/**
 * Sets a nested property using a dot-notation path.
 * e.g. setFieldValueByPath(someObj, "primary_guardian.address.locality", "Dallas")
 */
export function setFieldValueByPath(obj: any, path: string, newVal: any): void {
    const parts = path.split('.');
    const lastKey = parts.pop()!;
    const target = parts.reduce((acc, key) => (acc ? acc[key] : undefined), obj);
    if (target && lastKey in target) {
        target[lastKey] = newVal;
    }
}
