export const sixAgentCss = '/sixStandalone/sixStandalone.css';
export const sixAgentScript = '/sixStandalone/sixAgentInterface.js';
export const sixAgentWidth = 300;
export const sixAgentHeight = 600;

export enum SixAgentEventType {
    AGENT_INITIALIZED = 'AGENT_INITIALIZED',
    AGENT_LOG_ON = 'AGENT_LOG_ON',
    AGENT_LOG_OFF = 'AGENT_LOG_OFF',
    AGENT_STATE = 'AGENT_STATE',
    END_CONTACT = 'END_CONTACT',
    GET_USER_HISTORY = 'GET_USER_HISTORY',
    INCONTACT_EVENT = 'INCONTACT_EVENT',
    NEW_CONTACT = 'NEW_CONTACT',
    PARENT_WINDOW_CLOSED = 'PARENT_WINDOW_CLOSED',
    PENDING_FAMILY = 'PENDING_FAMILY',
    PHONE_LOG = 'PHONE_LOG',
    SHOW_OPENFRAME = 'SHOW_OPENFRAME'
}

interface SixAgentEventBase {
    type: SixAgentEventType;
}

export interface SixAgentEventAgentInitialized extends SixAgentEventBase {
    type: SixAgentEventType.AGENT_INITIALIZED;
    data?: { [key: string]: any };
}

export interface SixAgentEventAgentLogOn extends SixAgentEventBase {
    type: SixAgentEventType.AGENT_LOG_ON;
    data: {
        agentId: string;
        outboundSkillCount: string;
    };
}

export interface SixAgentContactData {
    ANI?: string;
    AllowDispositions: string;
    AnsMachineOverride: string;
    AnsMachineOverrideEndTime: string;
    BlendingToSkillName: string;
    CallType: string;
    ComplianceRecord: string;
    ConfirmationRequired: string;
    ContactID?: string;
    ContactId?: string;
    CustomData: string;
    DNIS: string;
    DeliveryType: string;
    DisconnectCode: string;
    ExternalId: string;
    FinalState: string;
    FromAddress?: string;
    IISHost: string;
    IsInbound: string;
    IsLinked: string;
    IsLogging: string;
    Label: string;
    LastStateChangeTime: string;
    LastStateChangeTimeUTC: string;
    MasterID?: string;
    MasterId?: string;
    OmniGroupId: string;
    OriginalState: string;
    OtherInformation: string;
    OtherInformationNewFormat: string;
    ParentContactId: string;
    ScreenPopUrl: string;
    Skill: string;
    StartTime: string;
    StartTimeUTC: string;
    Status: string;
    TimeZones: string;
    Timeout: string;
    Type: string;
    VCHost: string;
    WorkItemId?: string;
    WorkItemPayload?: string;
    WorkItemType?: string;
}

export interface SixAgentEventNewContact extends SixAgentEventBase {
    type: SixAgentEventType.NEW_CONTACT;
    data: SixAgentContactData;
}

export interface SixAgentEventParentWindowClosed extends SixAgentEventBase {
    type: SixAgentEventType.PARENT_WINDOW_CLOSED;
    data: {};
}

export interface SixAgentEventAgentState extends SixAgentEventBase {
    type: SixAgentEventType.AGENT_STATE;
    data: {
        AcwTimeout: string;
        CurrentOutReason: string;
        CurrentState: string;
        IISHost: string;
        IsAcw: string;
        NextIsAcw: string;
        NextStates: [{ [key: string]: string }];
        StartTime: string;
        StartTimeUTC: string;
        Type: string;
        VCHost: string;
    };
}

export interface SixAgentEventShowOpenframe extends SixAgentEventBase {
    type: SixAgentEventType.SHOW_OPENFRAME;
    data: {
        payloadId: string;
    };
}

export interface SixAgentEventPhoneLog extends SixAgentEventBase {
    type: SixAgentEventType.PHONE_LOG;
    data: {
        agent_name: string;
        ani: string;
        call_direction: string;
        call_id: string;
        call_type: string;
        contact: string;
        contact_id: string;
        contact_type: string;
        disposition: string;
        dnis: string;
        duration: string;
        duration_in_seconds: number;
        end_time: string;
        master_id: string;
        payloadId: string;
        phone_number: string;
        recording_url: string;
        skill_name: string;
        start_time: string;
    };
}

export interface SixAgentEventAgentLogOff extends SixAgentEventBase {
    type: SixAgentEventType.AGENT_LOG_OFF;
    data: {
        IISHost: string;
        Message: string;
        Success: string;
        Type: string;
        VCHost: string;
    };
}

export interface SixAgentEventGetUserHistory extends SixAgentEventBase {
    type: SixAgentEventType.GET_USER_HISTORY;
    data: {
        historyTypes: [{
            contactColumn: string;
            contactTable: string;
            isTask: string;
            title: string;
        }];
        payloadId: string;
        startDateTime: string;
        userSysId: string;
    };
}

export interface SixAgentEventEndContact extends SixAgentEventBase {
    type: SixAgentEventType.END_CONTACT;
    data: {
        ANI: string;
        AllowDispositions: string;
        AnsMachineOverride: string;
        AnsMachineOverrideEndTime: string;
        BlendingToSkillName: string;
        CallType: string;
        ComplianceRecord: string;
        ConfirmationRequired: string;
        ContactID?: string;
        ContactId?: string;
        CustomData: string;
        DNIS: string;
        DeliveryType: string;
        DisconnectCode: string;
        ExternalId: string;
        FinalState: string;
        IISHost: string;
        IsInbound: string;
        IsLinked: string;
        IsLogging: string;
        Label: string;
        LastStateChangeTime: string;
        LastStateChangeTimeUTC: string;
        MasterID?: string;
        MasterId?: string;
        OmniGroupId: string;
        OriginalState: string;
        OtherInformation: string;
        OtherInformationNewFormat: string;
        ParentContactId: string;
        ScreenPopUrl: string;
        Skill: string;
        StartTime: string;
        StartTimeUTC: string;
        Status: string;
        TimeZones: string;
        Timeout: string;
        Type: string;
        VCHost: string;
    };
}

interface InContactEvent {
    Type: string;
}

interface SixAgentCustomEvent {
    type: 'CustomEvent';
    eventName: 'CRM_ENTITY';
    data: string;
}

export interface SixAgentCrmEntityData {
    entity: string;
    entityId: string;
    familyId: string;
    customerPhone: string;
    contactID: string;
    schoolNum: string;
    email: string;
}

export interface SixAgentInContactEvent extends SixAgentEventBase {
    type: SixAgentEventType.INCONTACT_EVENT;
    data: {
        sessionId: string;
        events: Array<InContactEvent | SixAgentCustomEvent | SixAgentContactData>;
    };
}

export interface SixAgentPendingFamilySearchInfo {
    firstName: string;
    lastName: string;
}

export type SixAgentEvent = SixAgentEventNewContact |
    SixAgentEventParentWindowClosed |
    SixAgentEventAgentState |
    SixAgentEventShowOpenframe |
    SixAgentEventPhoneLog |
    SixAgentEventAgentInitialized |
    SixAgentEventAgentLogOn |
    SixAgentEventAgentLogOff |
    SixAgentEventGetUserHistory |
    SixAgentInContactEvent |
    SixAgentEventEndContact;

export interface SixAgentPrefillInfo {
    contactId: string;
    phoneNumber: string | null;
    email: string | null;
}

// which events we handle via INCONTACT_EVENT->Active vs CustomEvent.
export const iceHandledEvents = ['EmailContactEvent'];
