import { Component, Vue } from 'vue-property-decorator';

/**
 * Handles common API request functionality.
 */
@Component
export class ApiRequestMixin extends Vue {
    abortController: AbortController | null = null;

    /**
     * Clear the abort controller if it exists.
     */
    clearAbortController() {
        this.abortController = null;
    }

    /**
     * Create a new abort controller.
     * If there is already an abort controller, abort it first, clearing out any past requests on it.
     */
    setAbortController() {
        if (this.abortController) {
            this.abortController.abort();
            this.abortController = null;
        }
        this.abortController = new AbortController();
    }
}
